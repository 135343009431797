import { BROWSER_ROOT } from 'configuration';




function addParents(hierarchy, fileId){
    let parent = hierarchy[fileId];
    let parents = [];
    while (parent !== BROWSER_ROOT){
        parents.unshift(parent);
        parent = hierarchy[parent];
    }
    return parents;
}





export function browser_navigate(state, action) {
    const fileId = action.fileId;
    const children = action.result.result;
    const filesNormalized = action.result.entities.files;
    // get current files and folders
    const files = children.map(function(key){
        return filesNormalized[key]
    });
    // duplicate info
    const browserItems = {
        ...state.browserItems,
        ...filesNormalized,
    }

    // construct hierarchy
    const parents = {};
    for (let i = 0; i < children.length; i++) {
        parents[children[i]] = fileId;
    }
    const hierarchy = {
        children: {
            ...state.hierarchy.children,
            [fileId]: children
        },
        parents: {
            ...state.hierarchy.parents,
            ...parents,
        },
    };
    
    // construct chain
    let chain = addParents(hierarchy.parents, fileId).map(function (key) {
        return browserItems[key];
    });
    chain.push(browserItems[fileId]);

    return Object.assign({}, state, {
        hierarchy: hierarchy,
        files: files,
        folderChain: chain,
        browserItems: browserItems,
        currentId: fileId,
    });
}

export function browser_init_reducer(state, action) {
    return Object.assign({}, state, {
        initializing: true,
    });
}

export function browser_init_success_reducer(state, action) {
    const root = { id: action.fileId, name: 'root', isDir: true };
    return Object.assign({}, state, {
            initialized: true,
            initializing: false,
            browserItems: {
                [action.fileId]: root,
                [BROWSER_ROOT]: root,
            },
            hierarchy: {
                children: {BROWSER_ROOT: action.fileId},
                parents: {[action.fileId]: BROWSER_ROOT},
            },
            files: [],
            folderChain: [root],
    });
}

export function browser_init_failure_reducer(state, action) {
    return Object.assign({}, state, {
            initialized: false,
            initializing: false,
            error: action.error,

    });
}

export function browser_mkdir(state, action) {
    const childId = action.result.result;
    const filesNormalized = action.result.entities.files;
    return Object.assign({}, state, {
        files: [
            ...state.files,
            filesNormalized[childId],
        ]
    });
}

export function browser_delete(state, action) {
    const fileId = action.fileId;
    const files = [...state.files].filter((file) => file.id !== fileId);
    return Object.assign({}, state, {
        files: files,
    });
}
