import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { create, update } from 'actions/backendModel';
import { SubmitFormButton } from 'components/button';
import { DateInput } from "components/forms/DateInput";
import { ADMIN_LINK_PERMISSIONS, DATE_FORMAT } from 'configuration';
import { format } from 'date-fns';
import { ErrorMessage, Field, FieldArray, Form, Formik, getIn } from 'formik';
import { adminLink } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";
import * as Yup from 'yup';


const AccessLinkSchema = Yup.object().shape({
    id: Yup.number(),
    ro: Yup.boolean(),
    tokenLifeDate: Yup.date().required('Required'),
    metadata: Yup.array().of(
        Yup.object().shape({
            key: Yup.string().required('Required'),
            value: Yup.string().required('Required'),
          })
        ),
});

const transform = (values) => {
    let data = {
        access_level: values.ro ? ADMIN_LINK_PERMISSIONS.VIEW: ADMIN_LINK_PERMISSIONS.EDIT,
        token_life_seconds: values.tokenLifeDate,
        metadata: metadataToObject(values.metadata),
    }
    if(values.id){
        data.id = values.id
    }
    return data;
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    create, update,
};

const styles = (theme) => ({
});


const calculateTokenLife = (date) => {
    let now = new Date();
    let tokenLifeDate = new Date(date);
    return Math.ceil((tokenLifeDate - now) / 1000);
};

const metadataToObject = (metadata) => {
    return metadata.reduce((obj, item) => {
        return {
          ...obj,
          [item.key]: item.value,
        };
      }, {});
}

const metadataToArray = (metadata) => {
    return Object.keys(metadata).map((key) => {
        return {key: key, value: metadata[key]}
    })
}

const ArrayErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? error : null;
      }}
    />
  );
 


function isRO(link){
    if(link.upload_token && link.download_token){
        return false
    }
    return true
}


function getDayBefore(date){
    const d = new Date(date)
    d.setDate(d.getDate() - 1)
    return format(d, DATE_FORMAT)
}

const _Form = ({create, update, shareName, namespaceId, handleSuccess, link}) => {
    let defaultDate = new Date();
    defaultDate.setDate(defaultDate.getDate() + 10);
    let initialValues = {
        ro: true,
        tokenLifeDate: defaultDate,
        metadata: [],
    }
    let actionFn = create;
    let args = [namespaceId, shareName];
    if(link && link.id){
        initialValues = {
            ro: isRO(link),
            tokenLifeDate: new Date(link.expiration_date_str.split('-')),
            metadata: metadataToArray(link.metadata),
        }
        actionFn = update
        args = [namespaceId, shareName, link.hashid];
    }
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AccessLinkSchema}
            onSubmit={(values, actions) => {
                let payload =  transform(values)
                actionFn(adminLink, payload, ...args)
                    .then((data) => {
                        actions.setSubmitting(false);
                    })
                    .catch((error) => {
                        actions.setSubmitting(false);
                        if(error.error && error.error.non_field_errors){
                            actions.setStatus(error.error.non_field_errors[0]);
                        } else {
                            actions.setStatus(JSON.stringify(error.error));
                        }
                    })
                    .then(handleSuccess)
            }}>
            {formik => (
                <Form>
                    {link && 
                        <Field type='hidden' name="id" value={link.id} />
                    }
                    {formik.status &&
                        <FormLabel error={formik.status && true}>{formik.status}</FormLabel>
                    }
                     <br/>
                    <div>
                        <FormLabel error={formik.errors.ro && true}>ReadOnly</FormLabel>
                        { formik.values.ro &&  <FormHelperText>The link can only be used to download data</FormHelperText>}
                        { !formik.values.ro &&  <FormHelperText>The link can be used to download and upload data</FormHelperText>}
                        <FormGroup>
                                    <Switch
                                        checked={formik.values.ro}
                                        onChange={formik.handleChange}
                                        name="ro"
                                        color="primary"
                                    />
                        </FormGroup>
                        <ErrorMessage name="ro" />
                    </div>
                    <br/>
                    <div>
                        <FormLabel error={formik.errors.tokenLifeDate && true}>Choose the day it will expire</FormLabel>
                        <FormHelperText>The link will be valid until {getDayBefore(formik.values.tokenLifeDate)} ({DATE_FORMAT}) at 23:59.</FormHelperText>
                        <FormGroup>
                            <DateInput
                                    name="tokenLifeDate" type='text'
                                    formik={formik}
                                    value={formik.values.tokenLifeDate}
                                    error={formik.errors.tokenLifeDate && true}
                                    onChange={(date) => formik.setFieldValue('tokenLifeDate', format(date, DATE_FORMAT))}
                                    fullWidth required
                            />
                        </FormGroup>
                        <ErrorMessage name="tokenLifeDate" />
                    </div>
                    <div>
                        <br/>
                    <FormLabel>Metadata</FormLabel>
                            <FormGroup>
                                <FieldArray
                                    name="metadata"
                                    render={arrayHelpers => (
                                        <div>
                                            {formik.values.metadata.length > 0 ? (
                                                formik.values.metadata.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <FormLabel>Key</FormLabel>
                                                            <TextField 
                                                                onChange={formik.handleChange}
                                                                type='text'
                                                                name={`metadata.${index}.key`}
                                                                value={formik.values.metadata[index].key}
                                                                error={`formik.errors.metadata.${index}.key` && true}
                                                                />
                                                            <ErrorMessage name={`metadata.${index}.key`} />
                                                            <FormLabel>Value</FormLabel>
                                                            <TextField
                                                                onChange={formik.handleChange}
                                                                type='text'
                                                                name={`metadata.${index}.value`}
                                                                value={formik.values.metadata[index].value}
                                                                error={`formik.errors.metadata.${index}` && true}
                                                            />
                                                            <ArrayErrorMessage name={`metadata.${index}.value`} />
                                                            <button
                                                            type="button"
                                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >-</button>
                                                            <button
                                                                type="button"
                                                                onClick={() => arrayHelpers.insert(index, {key: '', value: ''})} // insert an empty string at a position
                                                            >+</button>
                                                        </div>
                                                    )
                                                })
                                                ) : (
                                                    <button type="button" onClick={() => arrayHelpers.push({key: '', value: ''})}>+</button>
                                                )
                                            }
                                        </div>
                                    )}
                                />
                                {/* <TextField placeholder="metadata"
                                       name="metadata"
                                       type='metadata'
                                       label="metadata"
                                       value={formik.values.metadata}
                                       onChange={formik.handleChange}
                                       help="Add any key values of form key1=value1&key2=value2&... (query string)"
                                       error={formik.errors.metadata && true}
                                       fullWidth autoFocus
                                /> */}
                            </FormGroup>
                            {/* <ErrorMessage name="metadata" /> */}
                    </div>
                    <br/>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <SubmitFormButton disabled={formik.isSubmitting}>
                                <Typography>Submit</Typography>
                                {formik.isSubmitting && <FontAwesomeIcon icon="snowflake" spin/>}
                            </SubmitFormButton>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleSuccess}>Cancel</Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
};

export const AccessLinkForm = wrapper(_Form, {
    conn: [mapStateToProps, mapDispatchToProps],
    style: styles});
