export function browsing_reducer(state, action) {
    const objects = action.result.entities['files'];
    return Object.assign({}, state, {
        files: objects,
    });
}


export function aspera_set_objects(state, action){
    const {connect, installer} = action;
    return Object.assign({}, state, {
        ...state,
        connect, installer,
    });
}

