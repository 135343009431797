import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Link } from '@reach/router';
import { del, list } from "actions/backendModel";
import AccessLinkModal from 'components/admin/AccessLinkModal';
import MailModal from 'components/MailModal';
import EventDialog from "components/admin/EventDialog";
import { confirm } from "components/Confirm";
import { adminLink } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";


const mapStateToProps = (state) => ({
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
});

const mapDispatchToProps = {
    del, list,
};



class AccessLinkTableItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleReviewClick = this.handleReviewClick.bind(this);
        this.handleReviewClose = this.handleReviewClose.bind(this);
        this.toggleEventLog = this.toggleEventLog.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.handleModalSuccess = this.handleModalSuccess.bind(this)
        this.toggleMailModal = this.toggleMailModal.bind(this);
        this.handleMailClick = this.handleMailClick.bind(this);
        this.state = {
            open: false,
            openMail: false,
            displayEventLog: false,
        }
    }

    handleModalSuccess(data){
        this.toggleModal();
    }

    toggleModal() {
        this.setState({open: !this.state.open})
    }

    handleEditClick(link){
        const that = this;
        return function() {
            that.setState({
                currentLink: link
            })
            that.toggleModal();
        }
    }

    toggleMailModal() {
        this.setState({openMail: !this.state.openMail})
    }

    handleMailClick(){
        this.toggleMailModal();
    }

    handleReviewClick(link){
        const that = this;
        return function() {
            that.setState({
                displayEventLog: true,
                currentLink: link
            })
        }
    }

    toggleEventLog(){
        const {displayEventLog} = this.state;
        this.setState({
            displayEventLog: !displayEventLog,
        })
    }

    handleReviewClose(){
        this.setState({
            displayEventLog: false,
            currentLink: null,
        })
    }

   
    
    handleDeleteClick(link){
        const {del, selectedNamespaceID, selectedShareName} = this.props;
        return function() {
            confirm({
                title: `Deleting link ${link.hashid} on Share ${link.share.name}`,
                message: "Please confirm..."
            })
                .then(() => {
                    del(adminLink, link.hashid, selectedNamespaceID, selectedShareName, link.hashid);
                })
                .catch(() => {
                    console.log('Cancel deletion');
                })
        }
    }

    render(){
        const {link, isHighlighted, selectedNamespaceID, selectedShareName} = this.props;
        if(!link){
            return null;
        }
        const {displayEventLog, open, currentLink} = this.state;
        return (
            <React.Fragment>
                <TableRow 
                    key={link.hashid} 
                    selected={isHighlighted}
                >
                    <TableCell component="th" scope="row">
                        <FontAwesomeIcon color={link.download_token ? 'green' : 'red'} icon={link.download_token ? 'check-circle' : 'times'} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <FontAwesomeIcon color={link.upload_token ? 'green' : 'red'} icon={link.upload_token ? 'check-circle' : 'times'} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Link to={"/browse/n/" + link.hashid}>link</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {link.page_password}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {link.expiration_date_str}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <EditRoundedIcon sx={{cursor: 'pointer'}} color='success' onClick={this.handleEditClick(link)} />
                        <DeleteRoundedIcon sx={{cursor: 'pointer'}} color='error' onClick={this.handleDeleteClick(link)} />
                        <PreviewRoundedIcon sx={{cursor: 'pointer'}} color="primary" onClick={this.toggleEventLog} />
                        <MailRoundedIcon sx={{cursor: 'pointer'}} color="secondary" onClick={this.handleMailClick} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {JSON.stringify(link.metadata)}
                    </TableCell>
                </TableRow>
                <EventDialog 
                    open={displayEventLog}
                    onHandleClose={this.toggleEventLog}
                    link={link} 
                />
                <AccessLinkModal
                    open={open}
                    action="Edit"
                    namespaceId={selectedNamespaceID}
                    shareName={selectedShareName}
                    handleSuccess={this.handleModalSuccess}
                    link={currentLink}
                />
                <MailModal
                    selectedShareName={selectedShareName}
                    openMail={this.state.openMail}
                    link={link}
                    handleSuccess={this.toggleMailModal}
                />

         </React.Fragment>

        )
    }
}

export default wrapper(AccessLinkTableItem, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
