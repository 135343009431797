import Box from '@mui/material/Box';
import { setCredentials } from "actions/aspera";
import { readWithAuth } from "actions/backendModel";
import LinkExpiryNotice from "components/browser/LinkExpiryNotice";
import UserInfoBox from "components/browser/UserInfoBox";
import UserInfoHelp from "components/browser/UserInfoHelp";
import { AccessLinkPasswordForm } from "components/forms/AccessLinkPasswordForm";
import { userLink } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";


const mapStateToProps = (state) => ({
    links: state.objects.links,
    selectedNamespaceID: state.admin.selectedNamespaceID,
    credentials: state.login.aspera,
});



const mapDispatchToProps = {
    readWithAuth, setCredentials,
};


class BrowserHelpPage extends React.Component {
    constructor(){
        super();
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    handleSuccess(data){
        const {setCredentials, hashid} = this.props;
        const {links} = this.props;
        const current = links[hashid];
        setCredentials({
            kind: 'link',
            id: hashid,
            accessKey: current.access_key,
            bearerToken: current.bearer_token,
            password: data,
        });
    }

    componentDidMount() {
        const {hashid, credentials, readWithAuth} = this.props;
        const credentialsSet =  credentials.kind === 'link' && credentials.id === hashid;
        if(credentialsSet){
            readWithAuth(userLink, credentials.password, hashid)
        }
    }


    render() {
        const {hashid, credentials} = this.props;
        const credentialsSet =  credentials.kind === 'link' && credentials.id === hashid;
        if(!credentialsSet){
            return (
                <div>
                    <AccessLinkPasswordForm hashid={hashid} handleSuccess={this.handleSuccess} />
                </div>
            );
        }
        const {links} = this.props;
        const current = links[hashid];
        if(!current){
            return <div>Loading ....</div>
        }
         // check link expiration
         const now = new Date()
         if(now >= new Date(current.expiration_date_str.split('-'))){
             return <LinkExpiryNotice link={current} />
         }
         
        return (
            <Box sx={{ height: '100%' }}>
                <UserInfoBox  hashid={hashid} />
                <UserInfoHelp hashid={hashid} />
            </Box>
        );
    }
}



export default wrapper(BrowserHelpPage, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: false,
    style: true,
});
