import { Schemas } from 'model/schema';
import { Actions, ASPERA_ENDPOINTS, BACKEND_ENDPOINTS } from "../configuration";


export const refresh = () => (dispatch, getState) => {
    const { currentId } = getState().browser;
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BROWSER.NAVIGATE,
            fileId: currentId,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(currentId),
            schema: [Schemas.FILE],
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            },
            resolve,
            reject,
        });
    });
};

export const goTo = (fileId) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BROWSER.NAVIGATE,
            fileId: fileId,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(fileId),
            schema: [Schemas.FILE],
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            },
            resolve,
            reject,
        });
    });
};


export const initialize = ({rootFileId, name}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.BROWSER_INIT,
            fileId: rootFileId,
            name: name,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(rootFileId),
            schema: [Schemas.FILE],
            errorMessage: "Cannot initialize connection with aspera HTS.",
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            },
            resolve,
            reject,
        });
    });
};

export const contactOwners = ({hashid, payload}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.CONTACT_OWNERS,
            url: BACKEND_ENDPOINTS.CONTACT_OWNERS(hashid),
            schema: null,
            requestData: {
                body: JSON.stringify(payload),
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};