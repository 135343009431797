import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { set_share } from "actions/admin";
import { create, read } from "actions/backendModel";
import AccessLinkActionBar from "components/admin/AccessLinkActionBar";
import AccessLinkTable from "components/admin/AccessLinkTable";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ShareModal from 'components/admin/ShareModal';
import { share } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const mapStateToProps = (state) => ({
    chain: state.browser.folderChain,
    namespaces: state.objects.namespaces,
    shares: state.objects.shares,
    selectedNamespaceID: state.admin.selectedNamespaceID,
    selectedShareName: state.admin.selectedShareName,
});

const mapDispatchToProps = {
    read, set_share, create,
};

class ShareUI extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.state = {
            showPass: false,
            hashid: null,
            showModal: false,
        }
        this.handleEyeClick = this.handleEyeClick.bind(this);
        this.makeshare = this.makeshare.bind(this);
    }

    makeshare(name) {
        const { create, read, selectedNamespaceID } = this.props;
        create(share, {
            name: name,
        }, selectedNamespaceID).then(
            //read(share, selectedNamespaceID, name)
        )
    }

    handleEyeClick() {
        const { showPass } = this.state;
        this.setState({
            showPass: !showPass
        })
    }

    componentDidMount() {
        // Extract hashid from the URL
        const params = new URLSearchParams(window.location.search);
        const hashid = params.get('hashid');

        if (hashid) {
            this.setState({ hashid });
        }
    }

    componentDidUpdate(prevProps) {
        const { chain, set_share, read, selectedNamespaceID, selectedShareName } = this.props;
        if (chain !== prevProps.chain) {
            if (chain.length > 1) {
                const s = chain[1];
                set_share(s.name);
                read(share, selectedNamespaceID, s.name)
                    .then((data) => {
                        console.log(data);
                    })
                    .catch(this.makeshare(s.name));
            } else {
                set_share(null);
            }
        }
        if (selectedShareName !== prevProps.selectedShareName) {
            const { shares } = this.props;
            console.group('selectedShareName');
            console.log(shares);
            console.log('selectedShareName:', selectedShareName)
            console.groupEnd();
        }
    }

    renderNamespaceAlert(namespace) {
        const now = new Date();
        if (now >= new Date(namespace.expiration_date_str.split('-'))) {
            return (<Alert severity="error">Namespace: {namespace.name} is expired.</Alert>);
        }
        return (<Alert severity="info">Namespace: {namespace.name} access expiration date: {namespace.expiration_date_str} </Alert>);
    }

    handleSuccess() {
        this.toggle();
    }

    toggle() {
        this.setState(prevState => ({ showModal: !prevState.showModal }));
    }

    render() {
        const { selectedNamespaceID, namespaces, shares, selectedShareName } = this.props;
        const namespace = namespaces[selectedNamespaceID];
        const { showModal } = this.state;

        if (!namespace) {
            return null;
        }
        const NamespaceAlert = this.renderNamespaceAlert(namespace);

        if (!selectedShareName) {
            return (
                <Box sx={{ width: '100%' }}>
                    <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                        <CardContent sx={{ p: 2 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="text.primary">
                                        No Share Selected
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Namespace: <Typography component="span" variant="body2" color="text.primary">{namespace.name}</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Expires: <Typography component="span" variant="body2" color="text.primary">{namespace.expiration_date_str}</Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Alert severity="info" sx={{ mt: 2 }}>Double-click on a share to view its links.</Alert>
                </Box>
            );
        }
        else {
            const share = shares[selectedShareName];
            const { showPass } = this.state;

            // Add a check to ensure share is defined
            if (!share) {
                return <Alert severity="error">Selected share not found.</Alert>;
            }

            return (
                <React.Fragment>
                    <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0, height: 'auto' }}>
                        <CardContent sx={{ pb: 0 }}>
                            <Grid container spacing={1} alignItems="center">
                                {selectedShareName && (
                                    <Grid item md={12}>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                            <Typography variant="h6" color="text.primary">
                                                Share: <strong>{selectedShareName}{share.description ? ` (${share.description})` : ''}</strong>
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<EditIcon />}
                                                onClick={this.toggle}
                                            >Edit</Button>
                                        </Box>
                                        <ShareModal
                                            showModal={showModal}
                                            namespaceId={selectedNamespaceID}
                                            shareName={selectedShareName}
                                            shareDescription={share.description}
                                            handleSuccess={this.handleSuccess}
                                            share={share}
                                        />
                                        <Divider sx={{ my: 1 }} />
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Namespace: <Typography component="span" variant="body2" color="text.primary">{namespace.name}</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Expires: <Typography component="span" variant="body2" color="text.primary">{namespace.expiration_date_str}</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        Encryption password:
                                        <Typography component="span" variant="body2" color="text.primary" sx={{ mx: 1 }}>
                                            {showPass ? shares[selectedShareName]?.content_protection_password : "••••••••••"}
                                        </Typography>
                                        <IconButton
                                            aria-label="Show namespace password"
                                            onClick={this.handleEyeClick}
                                            size="small"
                                        >
                                            <RemoveRedEyeRoundedIcon fontSize="small" color="primary" />
                                        </IconButton>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box sx={{ overflow: 'auto', pt: 2, height: 'auto' }}>
                        <AccessLinkActionBar />
                        <AccessLinkTable hashid={this.state.hashid} />
                    </Box>
                </React.Fragment>
            );
        }
    }
}

export default wrapper(ShareUI, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
