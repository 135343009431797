export const getLogin = (state) => state.login;

export const getAuthHeaders = (state) => {
    return {
        'X-Aspera-AccessKey': state.login.aspera.accessKey,
        'Authorization': `Bearer ${state.login.aspera.bearerToken}`,
    }
};


export const getConnect = (state) => state.aspera.connect;


export const getCurrentLink = (state) => {
    const id = state.login.aspera.id;
    return state.objects.links[id];
};
