import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { list } from "actions/backendModel";
import { linkEvent } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";


const mapStateToProps = (state) => ({
    events: state.objects.requestevents,
});

const mapDispatchToProps = {
    list,
};



class AccessLinkEvents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        }
    }
    componentDidMount(){
        const {link, list} = this.props;
        list(linkEvent, link.hashid)
            .then((data) => {
                this.setState({ready: true});
            })
            .catch((error) => {
                console.error('read events');
                console.error(error);
            })
    }

    renderEventItem(item){
        return (
            <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                        {item.datetime}
                </TableCell>
                <TableCell component="th" scope="row">
                        {item.user && item.user.username}
                </TableCell>
                <TableCell component="th" scope="row">
                        {item.method}
                </TableCell>
                <TableCell component="th" scope="row">
                        {item.remote_ip}
                </TableCell>
            </TableRow>
        )
    }

    render(){
        const {ready} = this.state;
        if(!ready){
            return null;
        }
        const {events} = this.props;
        if(!events){
            return (<Card>
                        <CardContent>
                            <Typography color="h6" gutterBottom>
                            No access recorded yet
                            </Typography>
                        </CardContent>
                    </Card>
            )
        }
        
        const Events = Object.keys(events).map( (key) => {
            const item = events[key];
            return this.renderEventItem(item);
        });
        return (
            <Card>
            <CardContent>
                <Typography color="h6" gutterBottom>
                     Access events
                </Typography>
                <TableContainer component={Paper}>
                <Table aria-label="access link table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Datetime</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Method</TableCell>
                            <TableCell>IP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Events}
                    </TableBody>
                </Table>
            </TableContainer>
            </CardContent>
            <CardActions>
            </CardActions>
        </Card>

            
        )
    }
}

export default wrapper(AccessLinkEvents, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
