import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { list } from "actions/backendModel";
import { AccessLinkForm } from "components/admin/forms/AccessLinkForm";
import React from 'react';
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    links: state.objects.links,
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
});

const mapDispatchToProps = {
    list,
};



class AccessLinkModal extends React.Component {

    render() {
        const {selectedShareName, classes, action, selectedNamespaceID, link, open, handleSuccess} = this.props;
        return (
            <Dialog
                open={open}
                onClose={function(){
                    handleSuccess();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>
                    {action} an access link for the share: {selectedShareName}
                </DialogTitle>
                <DialogContent>
                    <AccessLinkForm link={link} namespaceId={selectedNamespaceID} shareName={selectedShareName} handleSuccess={handleSuccess} />
                </DialogContent>
            </Dialog>
        )
    }
}

export default wrapper(AccessLinkModal, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
    style: true,
});
