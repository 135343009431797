import rootSaga from 'model/sagas';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { pReducer } from "./persist";

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-unused-vars
const preloadedState = {};

let storeOptions = [
  pReducer,
  preloadedState,
]

if(process.env.REACT_APP_ENVIRONMENT !== 'production'){
  console.warn('Not using production build', process.env.REACT_APP_ENVIRONMENT);
  storeOptions.push(
      composeWithDevTools(
        applyMiddleware(thunk, sagaMiddleware, createLogger()),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      )
} else {
  storeOptions.push(
      applyMiddleware(thunk, sagaMiddleware, createLogger()),
    )
}

const configureStore = (preloadedState) => {
  const store = createStore(...storeOptions);
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return {store, persistor};
};


export default configureStore;
