import { withStyles } from '@mui/styles';
import { Redirect } from "@reach/router";
import useNotifier from "lib/useNotifier";
import React from "react";
import { connect, useSelector } from 'react-redux';
import { Styles } from 'theme';
import winston from 'winston';

export const uniqId = () => {
    return Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now());
};

export const poll = (fn, args, timeout, interval) => {
    const endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    const checkCondition = function(resolve, reject) {
    // If the condition is met, we're done!
        const result = fn(...args);
        if (result) {
            resolve(result);
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
            reject(new Error('timed out for ' + fn + ': ' + args));
        }
    };
    return new Promise(checkCondition);
};


// winston logger https://www.npmjs.com/package/winston
const myFormat = winston.format.printf((
    { level, message, label, timestamp }) => {
    const msg = JSON.stringify(message);
    return `${level} [${label}]: ${msg}`;
});

export const logger = winston.createLogger({
    level: 'debug',
    format: myFormat,
    defaultMeta: { service: 'lft-frontend' },
    transports: [
    ],
});

if (process.env.NODE_ENV !== 'production') {
    logger.add(new winston.transports.Console());
}

function log({level, label}) {
    return function(message, meta){
        logger.log({
            level: level, label: label, message: message, meta: meta
        })
    }
}

export function l_debug(label){
    return log({level: 'debug', label: label})
}
export function l_error(label){
    return log({level: 'error', label: label})
}
// end logger config




// handy function to prevent use of switch statements
// https://redux.js.org/recipes/reducing-boilerplate#generating-reducers

export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    }
}

export const adminComponent = Component => props => {
    const login = useSelector(state => state.login);
    if (!login.token) return <Redirect from="" to="/login" noThrow />;
    return <Component {...props} />;
};


export const messageComponent = (Component) => {
    return (props) => {
      useNotifier();
      return <Component {...props} />;
    };
  };


export function wrapper(WrappedComponent, params) {
    const {
        style = null,
        styleOverride = null,
        conn = null,
        isAdmin = false,
        messages=false
    } = params;
    let comp = WrappedComponent;
    if (styleOverride) {
        const styles = {
            ...Styles,
            ...styleOverride,
        }
        comp = withStyles(styles)(comp);
    } else if (style){
        comp = withStyles(Styles)(comp);
    }
    if (conn) {
        comp = connect(...conn)(comp);
    }
    if(isAdmin) {
        comp = adminComponent(comp);
    }
    if(messages){
        comp = messageComponent(comp);
    }
    return comp;
}
