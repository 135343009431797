
export function backend_login_reducer(state, action){
    return Object.assign({}, state, {
        ...state,
        ...action.result.data,
    });
}



export function backend_logout_reducer(state, action){
    return Object.assign({}, state, {
        ...state,
        ...{
            token: null,
            expiry: null,
        },
    });
}


export function aspera_set_credentials(state, action){
    const {accessKey, bearerToken, kind, id, password} = action;
    return Object.assign({}, state, {
        ...state,
        aspera: {
            kind, id, accessKey, bearerToken, password
        }
    });
}
