import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactDOM from 'react-dom';
import { wrapper } from "utils";

class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    handleConfirm(){
        this.props.resolve();
    }

    handleCancel(){
        this.props.reject();
    }

    render() {
        const { message, title } = this.props;

        return (
            <Modal
                open={true}
                onClose={this.handleCancel}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={11} sm={6} md={4}>
                    <Paper elevation={3} sx={{ 
                        borderRadius: 2, 
                        p: 3, 
                        backgroundColor: 'background.paper',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Typography variant="h6"  gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                            {message}
                        </Typography>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Button 
                               variant="contained"
                               color="primary"
                               onClick={this.handleConfirm}
                            >
                                <Typography>Confirm</Typography>
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={this.handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Grid>
            </Modal>
        )
    }
}

const Element = wrapper(Confirm, {
    style: 'modal',
});

Element.defaultProps = {
    message: "Are you sure ?",
    title: "Please confirm"
};

export const confirm = (options) => new Promise(function(ok, cancel) {
    const wrapper = document.body.appendChild(document.createElement('div'));
    
    const cleanup = function () {
        ReactDOM.unmountComponentAtNode(wrapper);
        wrapper.remove();
    };

    const inner = new Promise(function (resolve, reject) {
        ReactDOM.render(<Element resolve={resolve} reject={reject} {...options} />, wrapper);
    });
    inner.then(ok)
        .catch(cancel)
        .finally(cleanup);
});

