import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { set_namespace } from "actions/admin";
import { setCredentials } from 'actions/aspera';
import { read } from "actions/backendModel";
import { goTo } from 'actions/browser';
import ShareUI from "components/admin/ShareUI";
import Navigator from "components/browser/Navigator";
import { namespace } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    namespaces: state.objects.namespaces,
    selectedNamespaceID: state.admin.selectedNamespaceID,
    credentials: state.login.aspera,
    selectedShareName: state.admin.selectedShareName,
});

const mapDispatchToProps = {
    read, goTo, setCredentials, set_namespace,
};

class Namespace extends React.Component {
    componentDidMount() {
        const {read, namespaceId, set_namespace, setCredentials} = this.props;

        read(namespace, namespaceId)
            .then((data) => {
                const {namespaces} = this.props;
                const current = namespaces[namespaceId];
                setCredentials({
                    kind: 'namespace',
                    id: namespaceId,
                    accessKey: current.edit_access_link.access_key,
                    bearerToken: current.edit_access_link.bearer_token,
                });
                set_namespace(namespaceId);
            })
            .catch((error) => {
                console.error('read namespace');
                console.error(error);
            })
    }

    render() {
        const {selectedNamespaceID, credentials, navigate} = this.props;
        const credentialsSet =  credentials.kind === 'namespace' && credentials.id === selectedNamespaceID;
        if(!selectedNamespaceID || !credentialsSet){
            return null;
        }
        const {namespaces} = this.props;
        const current = namespaces[selectedNamespaceID];
        return (
            <Grid container spacing={2} sx={{ height: '90vh', mt: 0 }}>
                <Grid item xs={6} sx={{ height: '78vh' }}>
                    <Navigator
                        path={this.props['*']}
                        name={current.name}
                        rootFileId={current.root_file_id}
                        navigate={navigate}
                        link={current.edit_access_link}
                        isAdmin={true}
                    />
                </Grid>
                <Grid item xs={6} sx={{ height: '78%' }}>
                    <ShareUI />
                </Grid>
            </Grid>

        );
    }
}

export default wrapper(Namespace, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
