import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Alert from '@mui/material/Alert';
import MuiCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    links: state.objects.links,
});


const Card = styled(MuiCard)(({ theme }) => ({
    width: '100%', 
    display: 'flex', 
    justifyContent: 'space-between', 
    flexDirection: 'column' ,
    padding: theme.spacing(1),
    margin: theme.spacing(2),
  }));


class UserInfoBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPass: false,
            copied: false,
            messageSent: false,
            tmid: null,
        }
        this.handleEyeClick = this.handleEyeClick.bind(this);
        this.handleCopyClick = this.handleCopyClick.bind(this);
        this.handleMessageSuccess = this.handleMessageSuccess.bind(this);
    }

    handleMessageSuccess(){
        this.setState({
            messageSent: true,
        });
    }
    
    handleEyeClick() {
        const {showPass} = this.state;
        this.setState({
            showPass: !showPass
        })
    }

    fadeOutCopy(){
        const {tm} = this.state;
        const that = this;
        if(tm){
            clearTimeout(tm);
        }
        const tm2 = setTimeout(function(){
            that.setState({
                copied: false
            })
        }, 2000);
        this.setState({tm: tm2});
    }

    handleCopyClick(){
        const {links, hashid} = this.props;
        const current = links[hashid];
        if(!current){
            return null;
        }
        const text = current.content_protection_password;
        if ('clipboard' in navigator) {
            this.setState({
                copied: true
            })
            navigator.clipboard.writeText(text);
            this.fadeOutCopy();
        }

    }

    renderAccessAlert(link, isExpired){
        if(isExpired){
            return (<Alert severity="error">Access expired the {link.expiration_date_str}</Alert>);
        }
        return (<Alert severity="info">Access expiration date: {link.expiration_date_str} </Alert>);
    }

    render() {
        const {links, hashid} = this.props;
        const current = links[hashid];
        if(!current){
            return null;
        }
        
        const {showPass, copied} = this.state;

        return (
            <Grid container alignItems="center">
                <Grid item style={{display: 'flex'}}>
                    <Card>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            Data is encrypted!
                        </Typography>
                        <Typography variant="subtitle1" color='text.primary'>
                            For the highest protection, your data will be downloaded in encrypted state!
                        </Typography>
                        <Typography variant="subtitle1" color='text.primary'>
                            Use the following passphrase for decryption:
                        </Typography>
                        <Typography variant="h6" color='text.disabled'>
                            {showPass ? current.content_protection_password : "••••••••••••••••••••"}
                        </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                        <IconButton aria-label="Show password" onClick={this.handleEyeClick}>
                            <RemoveRedEyeRoundedIcon  fontSize="large" color="primary"  />
                        </IconButton>
                        <IconButton aria-label="Copy password" onClick={this.handleCopyClick}>
                            <ContentCopyIcon  fontSize="large" color="primary"  />
                        </IconButton>
                            <Fade in={copied} timeout={100}>
                                <Typography variant="subtitle1" color='info.main'>Password copied</Typography>
                            </Fade>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item style={{display: 'flex'}}>
                    <Alert severity="info">Access expiration:  {current.expiration_date_str} </Alert>
                </Grid>
           </Grid>
        );
    }
}

export default wrapper(UserInfoBox, {
    conn: [mapStateToProps, null],
    isAdmin: false,
});
