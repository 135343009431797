import React from 'react';
import { wrapper } from "utils";
import { list } from "actions/backendModel";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import AccessLinkModal from 'components/admin/AccessLinkModal';

const mapStateToProps = (state) => ({
    links: state.objects.links,
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
});

const mapDispatchToProps = {
    list,
};

class AccessLinkActionBar extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.state = {
            open: false,
        };
    }

    handleSuccess(data) {
        this.toggle();
    }

    toggle() {
        this.setState(prevState => ({ open: !prevState.open }));
    }

    render() {
        const { selectedShareName, selectedNamespaceID } = this.props;
        const { open } = this.state;

        return (
            <AppBar position="static" color="default" sx={{ boxShadow: 0, borderRadius: 0 }}>
                <Box sx={{ padding: 1, paddingLeft: 2, paddingRight: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Typography variant="h6" color="inherit">
                            Access Links
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={this.toggle}
                        >
                            New access link
                        </Button>
                    </Box>
                    <AccessLinkModal
                        action="Create"
                        open={open}
                        namespaceId={selectedNamespaceID}
                        shareName={selectedShareName}
                        handleSuccess={this.handleSuccess}
                    />
                </Box>
            </AppBar>
        );
    }
}

export default wrapper(AccessLinkActionBar, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
