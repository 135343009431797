import Button from '@mui/material/Button';
import { Actions } from "configuration";
import React from 'react';
// yield put(enqueueSnackbar({
//     message: 'Failed fetching data.',
//     options: {
//         key: new Date().getTime() + Math.random(),
//         variant: 'warning',
//     }
// }));

const notif = (notification) => {
    const key = notification.options && notification.options.key;
    return {
        type: Actions.NOTIFICATIONS.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    }
}

const action = key => (
        <Button onClick={() => { closeSnackbar(key) }}>
            'Dismiss'
        </Button>
);

export const notifier = {
    warning: (msg) => {
        const payload = {
            message: msg,
            options: { variant: 'warning'}
        }
        return enqueueSnackbar(payload)
    },
    error: (err) => {
        console.log("notifier");
        console.log(err);
        let msg;
        // is object
        if(typeof err === 'string'){
            msg = err
        }
        else if(typeof err === 'object' && !Array.isArray(err) && err !== null){
            try {
                // login error
                msg = err.error.non_field_errors[0]
            } catch {
                console.error("Undefined error");
                console.error(err);
                msg = "Undefined error"
            }
        }
        const payload = {
            message: msg,
            options: { variant: 'error'}
        }
        return enqueueSnackbar(payload)
    },
    success: (msg) => {
        const payload = {
            message: msg,
            options: { variant: 'success'}
        }
        return enqueueSnackbar(payload)
    },
    info: (msg) => {
        const payload = {
            message: msg,
            options: { variant: 'info'}
        }
        return enqueueSnackbar(payload)
    }
}



export const enqueueSnackbar = (notification) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        const key = notification.options && notification.options.key;
        dispatch({
            type: Actions.NOTIFICATIONS.ENQUEUE_SNACKBAR,
            notification: {
                ...notification,
                key: key || new Date().getTime() + Math.random(),
                options: {
                    ...notification.options,
                    //action: action,
                }
            },
        })
    })
};

export const closeSnackbar = key => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.NOTIFICATIONS.CLOSE_SNACKBAR,
            dismissAll: !key, // dismiss all if no key has been defined
            key,
        });
    });
}

export const removeSnackbar = key => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type:Actions.NOTIFICATIONS.REMOVE_SNACKBAR,
            key,
        });
    });
}

export const success = notifier.success;