import { Actions, BACKEND_ENDPOINTS } from 'configuration';

//https://github.com/redux-saga/redux-saga/issues/161#issuecomment-191312502


export const login = (payload) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.LOGIN,
            url: BACKEND_ENDPOINTS.LOGIN,
            requestData: {
                body: JSON.stringify(payload),
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};

export const logout = () => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.LOGOUT,
            url: BACKEND_ENDPOINTS.LOGOUT,
            requestData: {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};