import { Actions, Events } from "../configuration";


export const list = (model, ...args) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.LIST),
            model: model,
            url: model.list_url(...args),
            requestData: {
                method: 'GET',
            },
            resolve,
            reject,
        });
    });
};


export const read = (model, ...args) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.READ),
            model: model,
            url: model.read_url(...args),
            requestData: {
                method: 'GET',
            },
            resolve,
            reject,
        });
    });
};

export const readWithAuth = (model, password, ...args) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.READ),
            model: model,
            url: model.read_url(...args),
            requestData: {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-accesslink-password': password,
                },
            },
            resolve,
            reject,
        });
    });
};


export const create = (model, payload, ...args) => (dispatch, getState) => {
    console.log('CREATE')
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.CREATE),
            model: model,
            url: model.create_url(...args),
            requestData: {
                body: JSON.stringify(payload),
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};

export const update = (model, payload, ...args) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.UPDATE),
            model: model,
            url: model.update_url(...args),
            requestData: {
                body: JSON.stringify(payload),
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};



export const del = (model, origin, ...args) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.DELETE),
            model: model,
            origin: origin,
            url: model.delete_url(...args),
            requestData: {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};


export const search = (model, ...args) => (dispatch, getState) => {
    console.log(model);
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.BACKEND.MODEL(Events.SEARCH),
            model: model,
            url: model.search_url(...args),
            requestData: {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            },
            resolve,
            reject,
        });
    });
};


