/* global AW4 */
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { setAsperaObjects } from 'actions/aspera';
import { refresh } from 'actions/browser';
import React from 'react';
import { Helmet } from "react-helmet";
import { wrapper } from "utils";


const connect_installer_path = '/aspera/4.1/v4/connectinstaller-4.min.js';
const web_path = '/aspera/4.1/v4/asperaweb-4.min.js';
const sdkLocation = '/aspera/4.1/v4';

const mapStateToProps = (state) => ({
    aspera: state.aspera,
    rootPath: state.aspera.rootPath
});

const mapDispatchToProps = {
    setAsperaObjects, refresh,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

class Installer extends React.Component {


    componentDidMount() {
    }


    constructor(props) {
        super(props);
        this.handleChangeClientState = this.handleChangeClientState.bind(this);
        this.triggerExtensionInstall = this.triggerExtensionInstall.bind(this);
        this.startConnect = this.startConnect.bind(this);
        this.checkExtensionInstall = this.checkExtensionInstall.bind(this);
        this.addStatusListeners = this.addStatusListeners.bind(this);
        this.addTransferListeners = this.addTransferListeners.bind(this);
        this.handleAsperaScriptLoaded = this.handleAsperaScriptLoaded.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);

        this.state = {
            initialized: false,
            extensionNeeded: null,
            extensionInstalled: null,
            connect: null,
            connectInstaller: null,
            status: null,
            snackOpen: true,
        }
    }

    handleSnackClose(event, reason){
        if (reason === 'clickaway') {
            return;
          }
        this.setState({
            snackOpen: false,
        })
    }

    handleAsperaScriptLoaded(){
        try {
            // eslint-disable-next-line no-unused-expressions
            AW4;
            this.checkExtensionInstall()
        } catch (e) {
            window.setTimeout(this.handleAsperaScriptLoaded, 1000);
        }
    }

    checkExtensionInstall(){
        const {setAsperaObjects} = this.props;
        const that = this;
        const connect = new AW4.Connect({
            sdkLocation: sdkLocation,
            minVersion: "4.1.0",
            dragDropEnabled: true
        });
        const connectInstaller = new AW4.ConnectInstaller({
            sdkLocation : sdkLocation
        });
        setAsperaObjects({
            connect: connect,
            installer: connectInstaller,
        });
        const extensionNeeded = connectInstaller.doesBrowserNeedExtensionStore();

        this.setState({
            connectInstaller: connectInstaller,
            connect: connect,
            extensionNeeded: extensionNeeded,
        });

        // check browser extension
        AW4.ConnectInstaller.supportsInstallingExtensions = false;

        connectInstaller.isExtensionInstalled(2000, {
            timedout: function() {
               that.setState({
                   initialized: true,
                   extensionInstalled: false,
               });
               that.addStatusListeners();
            },
            success: function() {
                console.log('Aspera browser extension is installed.');
                that.setState({
                   initialized: true,
                   extensionInstalled: true,
               });
                that.addStatusListeners();
                that.addTransferListeners();
                that.startConnect();
            }
        });
    }

    triggerExtensionInstall(){
        const {connectInstaller} = this.state;
        connectInstaller.startExtensionInstall();
    }

    addStatusListeners(){
        const {connect, connectInstaller} = this.state;
        const that = this;
        const statusEventListener = function (eventType, data, e) {
            let status = AW4.Connect.STATUS;
            if (eventType === AW4.Connect.EVENT.STATUS){
                that.setState({
                    status: data,
                });
                if (data === status.INITIALIZING) {
                    connectInstaller.showLaunching();
                }
                else if (data === status.FAILED) {
                    connectInstaller.showDownload();
                }
                else if (data === status.OUTDATED) {
                    connectInstaller.showUpdate();
                }
                else if (data === status.RUNNING) {
                    connectInstaller.connected();
                }
                else if(data === status.EXTENSION_INSTALL){
                    connectInstaller.startExtensionInstall();
                }
            }
        };
        // Attach installer events to session status.
        connect.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);
    }

    addTransferListeners(){
        const {connect} = this.state;
        const that = this;
        const transferEventListener = function (eventType, data, e) {
            if(data['result_count'] === 0){
                return;
            }
            const transfers = data['transfers'];
            let transfer;
            let hasComplete = false;
            for(let i = 0;i<transfers.length;i++){
                transfer = transfers[i]
                if(transfer['transfer_spec'] && transfer['transfer_spec']['direction'] === 'send' && transfer['previous_status'] === "running" && transfer["status"] === "completed"){
                    hasComplete = true;
                    break;
                }
            }        
            if(hasComplete){
                const {refresh} = that.props;
                refresh();
            }
        }
        connect.addEventListener(AW4.Connect.EVENT.TRANSFER, transferEventListener);
    }

    startConnect(){
        const {connect} = this.state;
        connect.initSession();
    }

    handleChangeClientState(newState, addedTags){
        if (addedTags && addedTags.scriptTags) {
            const scriptTag = addedTags.scriptTags[1];
            scriptTag.onload = this.handleAsperaScriptLoaded;
        }
    }


    renderMessage() {
        const {initialized, extensionNeeded, extensionInstalled, status} = this.state;
        let severity = "info";
        let message = '';
        if(!initialized){
            message = (<React.Fragment>Checking Aspera Connect® installation...</React.Fragment>)
        } else if (extensionNeeded && !extensionInstalled) {
            message = (<React.Fragment>
                    Aspera Extension is needed.
                    If you have already installed the extension,
                    please make sure you have authorized the extension to
                    access this page. Otherwise click on the following button to install it:
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.triggerExtensionInstall}
                        >Install
                    </Button>
                        </React.Fragment>
            )
        } else {
            const st = AW4.Connect.STATUS;
            if (status === st.INITIALIZING) {
                message = (<React.Fragment>Initializing</React.Fragment>)
            } else if (status === st.FAILED) {
                message = (<React.Fragment>Cannot link to Aspera Connect.
                    If you have already installed the program, please make sure it is authorized.</React.Fragment>);
                severity = 'error';
            }
            else if (status === st.EXTENSION_INSTALL) {
                message = (<React.Fragment>Installing extension.</React.Fragment>);
                severity = 'info';
            }
            else if (status === st.OUTDATED) {
                message = (<React.Fragment>Your Aspera Connect is outdated, please update.</React.Fragment>);
                severity = 'error';
            }
            else if (status === st.RUNNING) {
                message = (<React.Fragment>Aspera Connect installed and running.</React.Fragment>);
                severity = 'success';
            }
            else {
                message = (<React.Fragment>Application error, please refresh the browser.</React.Fragment>);
                severity = 'warning';
            }
        }
        return (
            <Alert 
                severity={severity}
                onClose={this.handleSnackClose}>
                    {message}
            </Alert>
        );
    }



    render() {
        const Message = this.renderMessage();
        const {snackOpen} = this.state;
            return (
                <div className="application">
                    <Helmet
                        onChangeClientState={this.handleChangeClientState}>
                        <script type="text/javascript" src={connect_installer_path} />;
                        <script type="text/javascript" src={web_path} />;
                    </Helmet>
                    <div id="connect_installer" style={{display: 'none', align:'center'}}></div>
                    <Snackbar 
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    >
                            {Message}
                        </Snackbar>
                        </div>
            );
    }
}

export default wrapper(Installer, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: false,
});


