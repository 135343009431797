import { ThemeProvider } from '@mui/material/styles';
import AppRouter from "AppRouter";
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from 'store';
import { theme } from 'theme';
import './App.css';

class App extends React.Component {
  render() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider theme={theme}>
                <SnackbarProvider>
                  <AppRouter />
                </SnackbarProvider>
              </ThemeProvider>
            </PersistGate>
        </Provider>
    );
  }
}


// if (process.env.NODE_ENV === 'production') {
//   // eslint-disable-next-line no-unused-vars
//   // eslint-disable-next-line no-var
//   var configureStore = require('./store/StoreProd').default;
// } else {
//   // eslint-disable-next-line no-unused-vars
//   // eslint-disable-next-line no-var
//   var configureStore = require('./store/StoreDev').default;
// }
const {store, persistor} = configureStore();

export default App;
