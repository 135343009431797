import { normalize, schema } from 'normalizr';

// BACKEND OBJECTS
const namespaceSchema = new schema.Entity('namespaces', {}, {
    idAttribute: (nms) => nms.pk,
});

const linkSchema = new schema.Entity('links', {}, {
    idAttribute: (link) => link.hashid,
});

const shareEmailSchema = new schema.Entity('shareEmail', {}, {
    idAttribute: (shareEmail) => shareEmail.id,
});

const shareSchema = new schema.Entity('shares', {}, {
    idAttribute: (share) => share.name,
    description: (share) => share.description,
});

// see https://chonky.io/docs/2.x/basics/files#the-filedata-type for some data types
const fileSchema = new schema.Entity('files', {}, {
    idAttribute: (file) => file.id,
    processStrategy: (value, parent, key) => {
        return Object.assign(value, {
            isDir: value.type === 'folder',
            description: value.description || '',
            modDate: value.modified_time,
        });
    }
});


const linkEvent = new schema.Entity('requestevents', {}, {
    idAttribute: (re) => re.id,
});


export const Schemas = {
    LINK: linkSchema,
    NAMESPACE: namespaceSchema,
    SHARE: shareSchema,
    FILE: fileSchema,
    LINKEVENT: linkEvent,
    LINKEVENTS: [linkEvent],
    SHAREEMAIL: shareEmailSchema,
};

export const normalizeData = (data, schema) => {
    return normalize(data, schema);
};

