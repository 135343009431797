import { BACKEND_ENDPOINTS, Events } from 'configuration';
import { Schemas } from 'model/schema';
import { normalize } from 'normalizr';



export class BackendModel {

    constructor({name, stateAttribute, apiAttribute, schema, list_url, read_url, search_url, create_url, delete_url, update_url}) {
        this.name = name;
        this.stateAttribute = stateAttribute;
        this.apiAttribute = apiAttribute;
        this.schema = schema;
        this.list_url = list_url;
        this.read_url = read_url;
        this.search_url = search_url;
        this.create_url = create_url;
        this.delete_url = delete_url;
        this.update_url = update_url;
    }

    static reduce = {
        list(state, action) {
            const objects = action.result.entities[action.model.apiAttribute];
            return Object.assign({}, state, {
                [action.model.stateAttribute]: objects,
            });
        },
        read(state, action) {
            const objectId = action.result.result;
            const object = action.result.entities[action.model.apiAttribute][objectId];
            return Object.assign({}, state, {
                [action.model.stateAttribute]: {
                    ...state[action.model.stateAttribute],
                    [objectId]: object,
                },
            });
        },
        create(state, action) {
            const objectId = action.result.result;
            const object = action.result.entities[action.model.apiAttribute][objectId];
            return Object.assign({}, state, {
                [action.model.stateAttribute]: {
                    ...state[action.model.stateAttribute],
                    [objectId]: object,
                },
            });
        },
        delete(state, action) {
            const objectId = action.origin;
            const objects = {...state[action.model.stateAttribute]};
            delete objects[objectId];
            return Object.assign({}, state, {
                [action.model.stateAttribute]: objects,
            });
        },
        update(state, action) {
            const objectId = action.result.result;
            const object = action.result.entities[action.model.apiAttribute][objectId];
            return Object.assign({}, state, {
                [action.model.stateAttribute]: {
                    ...state[action.model.stateAttribute],
                    [objectId]: object,
                },
            });
        }
    };

    normalize(type, data){
        let sch = this.schema;
        if(type.slice(-6, type.length) === Events.DELETE){
            return // nothing to normalise on a DELETE method
        }
        if(type.slice(-4, type.length) === Events.LIST){
            sch = [this.schema];
        }
        return normalize(data, sch);
    }

}

// OBJECTS
export const namespace = new BackendModel({
    name: 'Namespace',
    stateAttribute: 'namespaces',
    apiAttribute: 'namespaces',
    schema: Schemas.NAMESPACE,
    list_url: () =>  BACKEND_ENDPOINTS.NAMESPACES,
    read_url: (pk) => BACKEND_ENDPOINTS.NAMESPACE(pk)
});

export const share = new BackendModel({
    name: 'Share',
    stateAttribute: 'shares',
    apiAttribute: 'shares',
    schema: Schemas.SHARE,
    read_url: (namespaceId, name) => BACKEND_ENDPOINTS.SHARE(namespaceId, name),
    create_url: (namespaceId) => BACKEND_ENDPOINTS.SHARES(namespaceId),
});


export const adminLink = new BackendModel({
    name: 'Link',
    stateAttribute: 'links',
    apiAttribute: 'links',
    schema: Schemas.LINK,
    list_url: (namespaceId, shareName) =>  BACKEND_ENDPOINTS.ADMIN_LINKS(namespaceId, shareName),
    create_url: (namespaceId, shareName) => BACKEND_ENDPOINTS.ADMIN_LINKS(namespaceId, shareName),
    read_url: (namespaceId, shareName, pk) => BACKEND_ENDPOINTS.ADMIN_LINK(namespaceId, shareName, pk),
    update_url: (namespaceId, shareName, pk) => BACKEND_ENDPOINTS.ADMIN_LINK(namespaceId, shareName, pk),
    delete_url: (namespaceId, shareName, pk) => BACKEND_ENDPOINTS.ADMIN_LINK(namespaceId, shareName, pk),
});

export const userLink = new BackendModel({
    name: 'Link',
    stateAttribute: 'links',
    apiAttribute: 'links',
    schema: Schemas.LINK,
    read_url: (hashid) => BACKEND_ENDPOINTS.USER_LINK(hashid),
    search_url: (hashid) => BACKEND_ENDPOINTS.SEARCH_USER_LINK(hashid),
});

export const linkEvent = new BackendModel({
    name: 'LinkEvent',
    stateAttribute: 'requestevents',
    apiAttribute: 'requestevents',
    schema: Schemas.LINKEVENT,
    list_url: (hashid) => BACKEND_ENDPOINTS.LINKEVENT(hashid),
});

export const shareEmail = new BackendModel({
    name: 'ShareEmail',
    stateAttribute: 'shareEmail',
    apiAttribute: 'shareEmail',
    schema: Schemas.SHAREEMAIL,
    create_url: () => BACKEND_ENDPOINTS.ADMIN_SHARE_EMAIL,
});

