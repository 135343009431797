import { download, mkdir, remove, upload } from 'actions/aspera';
import { create } from 'actions/backendModel';
import { goTo, initialize, refresh } from 'actions/browser';
import {
    ChonkyActions, FileBrowser,
    FileContextMenu,
    FileList,
    FileNavbar,
    FileToolbar
} from 'chonky';
import { ACTIONS, getAdminActions, getFileActions } from 'components/browserFileActions';
import { confirm } from "components/Confirm";
import { share } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';


const mapStateToProps = (state) => ({
    files: state.browser.files,
    fileStore: state.aspera.files,
    folderChain: state.browser.folderChain,
    connect: state.aspera.connect,
    root: state.browser.browserItems.root,
    token: state.login.token,
    selectedNamespaceID: state.admin.selectedNamespaceID,
    selectedShareName: state.admin.selectedShareName,
});

const mapDispatchToProps = {
    initialize, goTo, upload, mkdir, remove, download, create, refresh,
};







class Navigator extends React.Component {
    constructor(props) {
        super(props);
        this.handleAction = this.handleAction.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.uploadDirectory = this.uploadDirectory.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.shouldIStayOrShouldGo = this.shouldIStayOrShouldGo.bind(this);
        this.nav = this.nav.bind(this);
        this.state = {
            isLoading: true,
            filesArray: [],
            openDialog: false,
            name: '',
            description: ''
        }
    }

    componentDidMount() {
        const {initialize, name, rootFileId, path, files, selectedNamespaceID} = this.props;
        initialize({rootFileId: rootFileId, name: name}).then((onResolved) => {
            this.nav(rootFileId, path)
        },
        (onRejected) => {
            console.error("Browser initializatin failed");
        }
        );
        if (selectedNamespaceID) {
            this.updateFiles(files);
        } else {
            this.setState({filesArray: files});
        }
    }

    componentDidUpdate(prevProps){
        // update the URL with the reach navigate function
        const {folderChain, navigate, files, selectedNamespaceID} = this.props;
        if (folderChain.length !== prevProps.folderChain.length){
            //const chainIndex = folderChain.findIndex( ({ id }) => id === fileId );
            let goto;
            goto = folderChain.slice(1)
                .map(({name}) => name)
                .join('/');
                if(goto.startsWith('/')){
                    goto = goto.slice(1);
                }
            navigate(goto);
        }
        if (prevProps.files !== files && selectedNamespaceID) {
            this.updateFiles(files);
        }
    }

    nav(fileId, path){
        const {goTo} = this.props;
        if(path){
            goTo(fileId).then((ok) => this.shouldIStayOrShouldGo(path));
        } else {
            goTo(fileId).then((ok) => this.setState({
                isLoading: false,
            }));
        }
    }

    shouldIStayOrShouldGo(path){
        const {files} = this.props;
        const [nextPath, ...nextnextPath] = path.split('/');
        const nextItem = files.find(function(item){
            return item.name === nextPath;
        });
        if(nextItem){
            this.nav(nextItem.id, nextnextPath.join('/'));
        } else {
            this.setState({
                isLoading: false,
            })
            console.error(`The path to ${nextPath} does not exists anymore so we stop here.`)
        }
    }

    getCurrentPath(){
        const {folderChain, root} = this.props;
        return folderChain
            .reduce(function(acc, current){
                return `${acc}/${current.name}`;
                }, '')
            .substring(1)
            .substring(root.name.length);
    }

    getCurrentId(){
        const {folderChain} = this.props;
        return folderChain[folderChain.length -1].id;
    }

    handleAction(data){
        const {goTo, isAdmin} = this.props;
        switch(data.id){
            case ChonkyActions.OpenFiles.id:
                if(data.payload.targetFile.isDir){
                    this.nav(data.payload.targetFile.id)
                }
                break;
            case ACTIONS.UPLOAD.id:
                this.uploadFiles();
                break;
            case ACTIONS.UPLOAD_DIR.id:
                this.uploadDirectory();
                break;
            case ACTIONS.DOWNLOAD.id:
                this.downloadFiles(data.state.selectedFiles);
                break;
            case ACTIONS.DELETE.id:
                this.delete(data.state.selectedFiles);
                break;
            case ACTIONS.MKDIR.id:
                this.makedir();
                break;
            case ACTIONS.MKSHARE.id:
                if(isAdmin){
                    this.makeshare();
                }
                break;
            default: break;
        }
    }

    delete(selectedFiles){
        const {remove} = this.props;
        const Files = selectedFiles.map((file) => <li>{file.type} {file.name} ({file.size})</li>);
        confirm({
            title: `Deleting ${selectedFiles.length} files and/or folders`,
            message: <ul>{Files}</ul>
        })
            .then(() => {
                selectedFiles.map( (file) => remove({fileId: file.id}));
            })
            .catch(() => {
                console.log('Cancel deletion');
            })
    }

    uploadFiles(){
        const {connect, upload} = this.props;
        const that = this;
        connect.showSelectFileDialog({
            success: function(dataTransferObj){
                upload({
                    path: that.getCurrentPath(),
                    files: dataTransferObj.dataTransfer.files,
                });
            },
            error: function(message){
                console.error(message)
            }
        });
    }

    uploadDirectory(){
        const {connect, upload} = this.props;
        const that = this;
        connect.showSelectFolderDialog({
            success: function(dataTransferObj){
                upload({
                    path: that.getCurrentPath(),
                    files: dataTransferObj.dataTransfer.files,
                });
            },
            error: function(message){
                console.error(message)
            }
        });
    }

    downloadFiles(selectedFiles){
        const {download} = this.props;
        download({
            path: this.getCurrentPath(),
            files: selectedFiles
        });
    }

    makedir() {
        const {mkdir, refresh} = this.props;
        let name = prompt('Enter folder name');
        if(name && name.trim()){
            mkdir({
                folderName: name.trim(),
                fileId: this.getCurrentId(),
            }, name).then(() => {
                refresh()
                }
            )
        }
    }

    makeshare() {
        const {selectedShareName} = this.props;
        if(selectedShareName){
            alert("You can't create a share here. It is only possible in the root folder of a namespace.");
            return;
        }
        this.setState({openDialog: true});
    }

    updateShare = async (name, description) => {
        const {token, selectedNamespaceID} = this.props;
        try {
            await fetch(`/api/namespaces/${selectedNamespaceID}/shares/update_description/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({"namespace": selectedNamespaceID, "name": name, "description": description}),
            }).then(response => {
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            });
        } catch (error) {
            console.error('Error:', error);
        }
    }
    handleClose = () => {
        this.setState({openDialog: false});
    }

    handleSave = () => {
        const {name, description} = this.state;
        const {create, refresh, selectedNamespaceID} = this.props;

        if (name && name.trim()) {
            create(share, {
                name: name.trim(),
            }, selectedNamespaceID).then(() => {
                if (selectedNamespaceID) {
                    this.updateShare(name, description);
                }
                this.setState({name: '', description: ''});
                refresh()
            });
        }
        this.handleClose();
    }

    handleChange = (field) => (event) => {
        this.setState({[field]: event.target.value});
    }

    updateFiles = async (files) => {
        const {token, selectedNamespaceID} = this.props;
        try {
            let data = await fetch(`/api/namespaces/${selectedNamespaceID}/shares/retrieve_description/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({"names": files.filter(file => file.isDir).map(file => file.name)}),
            }).then(response => {
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                return response.json();
            });
            this.setState({
                filesArray: files.map(file => {
                    let description = data.find(obj => obj.name === file.name)?.description || '';
                    return {
                        ...file,
                        description,
                        name: description ? `${file.name} (${description})` : file.name
                    };
                })
            });
        } catch (error) {
            console.error('Error:', error);
        }
    }

    browser() {
        const {filesArray} = this.state;
        const {files, folderChain, link, isAdmin, selectedShareName} = this.props;
        if(!files || !folderChain){
            return null;
        }

        const {isLoading} = this.state;
        if(isLoading){
            return (
                <Box sx={{ width: '100%' }}>
                    <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                        <CardContent sx={{ p: 2 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="text.primary">
                                        Loading browser...
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="text.secondary">
                                        Please wait while the browser is loading.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            );
        }
        let fileActions = isAdmin ? getAdminActions(selectedShareName) : getFileActions(link);
        return (
                <FileBrowser
                    files={filesArray && filesArray.length > 0 ? filesArray : files}
                    folderChain={folderChain}
                    onFileAction={this.handleAction}
                    fileActions={fileActions}
                    disableDefaultFileActions={true}
                    defaultFileViewActionId={ChonkyActions.EnableListView.id}
                    style={{ height: '100%' }}
                >
                    <FileNavbar />
                    <FileToolbar/>
                    <FileList />
                    <FileContextMenu/>
                </FileBrowser>
        );
    }


    render() {
        const {openDialog, name, description} = this.state;
        const Browser = this.browser();
        if(!Browser){
            return null;
        }
        return (
            <React.Fragment>
                {Browser}
                <Dialog open={openDialog} onClose={this.handleClose}>
                    <DialogTitle>Add a folder</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Folder name"
                            type="text"
                            fullWidth
                            value={name}
                            onChange={this.handleChange('name')}
                        />
                        <TextField
                            margin="dense"
                            id="description"
                            label="Folder description"
                            type="text"
                            multiline
                            fullWidth
                            value={description}
                            onChange={this.handleChange('description')}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}

export default wrapper(Navigator, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: false,
});
