import React from "react";
import { wrapper } from "utils";

class AdminContext extends React.Component {
    render() {
        return this.props.children;
    };
}

export default wrapper(AdminContext, {
    isAdmin: true,
});
