import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Link } from '@reach/router';
import { search } from "actions/backendModel";
import { userLink } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    search,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class SearchLinkModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchTerm: '',
            result: null,
        }
        this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchLinkButton = this.handleSearchLinkButton.bind(this);
    }
    
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyboardEvent);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyboardEvent);
    } 

    handleChange = (e) => {
        this.setState({ searchTerm: e.target.value });
   };
    
    handleKeyboardEvent(event){
        if (event.code === 'KeyS') {
            if (!this.props.open){
                event.preventDefault(); // Don't update the input value when the shortcut is pressed
            }
            this.props.onOpen();
        } else if(event.code === 'Enter'){
            this.handleSearchLink();
        }
    }
    handleSearchLink(){
        const {searchTerm} = this.state;
        const {search} = this.props;
        if(searchTerm){
            const hashid = searchTerm.split('/')[searchTerm.split('/').length -1];
            search(userLink, hashid).then((data) => {
                this.setState({result: data});
            }, (err) => {
                this.setState({result: null});
                console.error("error")
            })

        }
    }
    handleSearchLinkButton(event){
        event.preventDefault();
        this.handleSearchLink();
    }

    handleClose() {
        this.setState({
            searchTerm: '',
            result: null,
        });
        this.props.onClose();
    }

    renderLink(link){
        if(!link){
            return null;
        }
        const namespaceLink = `/admin/namespaces/${link.share.namespace.id}`;
        const shareLink = `${namespaceLink}/${link.share.name}?hashid=${link.hashid}`;
        const browseLink = `/browse/n/${link.hashid}`;

        return (
            <TableRow key={link.hashid}>
                <TableCell>
                    <Link to={shareLink}>
                        <Typography variant="body2">
                            {`${link.share.name}${link.share.description ? ` (${link.share.description})` : ''}`}
                        </Typography>
                    </Link>
                </TableCell>
                <TableCell>
                    <Link to={namespaceLink}>
                        <Typography variant="body2">{link.share.namespace.name}</Typography>
                    </Link>
                </TableCell>
                <TableCell>
                    <FontAwesomeIcon color={link.download_token ? 'green' : 'red'} icon={link.download_token ? 'check-circle' : 'times'} />
                </TableCell>
                <TableCell>
                    <FontAwesomeIcon color={link.upload_token ? 'green' : 'red'} icon={link.upload_token ? 'check-circle' : 'times'} />
                </TableCell>
                <TableCell>
                    <Link to={browseLink}>
                        <Typography variant="body2">link</Typography>
                    </Link>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{link.page_password}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{link.expiration_date_str}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body2">{JSON.stringify(link.metadata)}</Typography>
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const {searchTerm, result} = this.state;
        const {open} = this.props;
        let Link = null;
        if (result) {
            const linkObjects = Object.values(result.entities.links).flatMap(link => Object.values(link));
            Link = linkObjects.map(link => this.renderLink(link));
        }

        return (
            <Dialog
                maxWidth="lg"
                fullWidth
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh', width: '100%' }}>
                    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, bgcolor: 'background.paper' }}>
                        <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                            <CardContent sx={{ p: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            variant="outlined"
                                            label="Search Links/Shares"
                                            value={searchTerm}
                                            onChange={this.handleChange}
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    ev.preventDefault();
                                                    this.handleSearchLink();
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={this.handleSearchLinkButton}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="primary" onClick={this.handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        
                    </Box>
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                        <TableContainer component={Paper} sx={{ height: '100%' }}>
                            <Table stickyHeader aria-label="access link table" sx={{ p: 2 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="subtitle2">Share</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Namespace</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Download</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Upload</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Link</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Access password</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Expiration date</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle2">Metadata</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Link ? Link : (
                                        <TableRow>
                                            <TableCell colSpan={8} align="center">
                                                <Typography variant="body2">No links available</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Dialog>
        )
    }
}

export default wrapper(SearchLinkModal, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
