import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { list } from "actions/backendModel";
import React from 'react';
import { wrapper } from "utils";
import { MailForm } from "components/admin/forms/MailForm";



const mapStateToProps = (state) => ({
    links: state.objects.links,
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
});

const mapDispatchToProps = {
    list,
};



class MailModal extends React.Component {
    

    render() {
        const {selectedShareName, openMail, link, handleSuccess} = this.props;
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={openMail}
                onClose={function(){
                    handleSuccess();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>
                    Sending Email to give access to {selectedShareName} 
                </DialogTitle>
                <DialogContent>
                    <MailForm link={link} shareName={selectedShareName} handleSuccess={handleSuccess}/>
                </DialogContent>
            </Dialog>
        )
    }
}

export default wrapper(MailModal, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
    style: true,
});
