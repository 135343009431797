import { ChonkyActions, ChonkyIconName, defineFileAction } from 'chonky';

export const ACTIONS = {
    MKDIR: {
        id: 'mkdir',
            requiresSelection: false,
            button: {
            name: 'Create Directory',
                toolbar: true,
                iconOnly: true,
                icon: ChonkyIconName.folderCreate,
        },
    },
    MKSHARE: {
        id: 'mkshare',
            requiresSelection: false,
            button: {
            name: 'Create a share',
                toolbar: true,
                iconOnly: true,
                icon: ChonkyIconName.folderCreate,
        },
    },
    UPLOAD: {
        id: 'upload_files',
        requiresSelection: false,
        button: {
            name: 'Upload files',
            toolbar: true,
            iconOnly: true,
            icon: ChonkyIconName.upload,
        }
    },
    UPLOAD_DIR: {
        id: 'upload_directory',
        requiresSelection: false,
        button: {
            name: 'Upload directory',
            toolbar: true,
            iconOnly: true,
            icon: ChonkyIconName.folderOpen,
        }
    },
    DOWNLOAD: {
        id: 'download_files',
        requiresSelection: true,
        button: {
            name: 'Download files',
            toolbar: true,
            contextMenu: true,
            iconOnly: true,
            icon: ChonkyIconName.download,
        }
    },
    DELETE: {
        id: 'delete_files',
        requiresSelection: true,
        button: {
            name: 'Delete files',
            toolbar: true,
            iconOnly: true,
            contextMenu: true,
            icon: ChonkyIconName.trash,
        },
        payload: {},
    },
};

const mkdirAction = defineFileAction(ACTIONS.MKDIR);
const uploadAction = defineFileAction(ACTIONS.UPLOAD);
const uploadDirAction = defineFileAction(ACTIONS.UPLOAD_DIR);
const downloadAction = defineFileAction(ACTIONS.DOWNLOAD);
const deleteAction = defineFileAction(ACTIONS.DELETE);
const mkshareAction = defineFileAction(ACTIONS.MKSHARE);

export const getFileActions = (link) => {
    if(!link){
        return [];
    }
    if(link.upload_token){
        return [
            mkdirAction,
            uploadAction,
            uploadDirAction,
            deleteAction,
            downloadAction,
            ChonkyActions.EnableListView,
            ChonkyActions.EnableGridView,
        ]
    }
    return [
        downloadAction,
        ChonkyActions.EnableListView,
        ChonkyActions.EnableGridView,
    ]
};


export const getAdminActions = (shareName) => {
    if(shareName){
        return [
            deleteAction,
            ChonkyActions.EnableListView,
            ChonkyActions.EnableGridView,
        ]
    }
    return [
        mkshareAction,
        deleteAction,
        ChonkyActions.EnableListView,
        ChonkyActions.EnableGridView,
    ]
};
