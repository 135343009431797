import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowAltCircleDown,
  faArrowCircleRight,
  faArrowRight, faArrowUp,
  faCheckCircle, faCheckSquare, faCircle, faCoffee, faCog,
  faCogs, faEdit, faEye, faGripVertical, faPen, faPlus, faSignInAlt,
  faSignOutAlt, faSnowflake, faThLarge, faTimes,
  faTimesCircle, faTrash, faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import CachedIcon from '@mui/icons-material/Cached';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CopyAllRoundedIcon from '@mui/icons-material/CopyAllRounded';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
import CropOriginalRoundedIcon from '@mui/icons-material/CropOriginalRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import LayersClearRoundedIcon from '@mui/icons-material/LayersClearRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import PanToolRoundedIcon from '@mui/icons-material/PanToolRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SelectAllRoundedIcon from '@mui/icons-material/SelectAllRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import ToggleOffRoundedIcon from '@mui/icons-material/ToggleOffRounded';
import ToggleOnRoundedIcon from '@mui/icons-material/ToggleOnRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { createTheme } from '@mui/material/styles';
import { ChonkyIconName, setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import React from 'react';







// FA library: https://github.com/FortAwesome/react-fontawesome#installation
library.add(
    faCheckSquare,
    faCoffee,
    faSnowflake,
    faArchive,
    faArrowAltCircleDown,
    faArrowCircleRight,
    faArrowRight,
    faTimes,
    faTimesCircle,
    faEdit,
    faSignInAlt,
    faSignOutAlt,
    faTrash,
    faPlus,
    faGripVertical,
    faUserPlus,
    faThLarge,
    faCircle,
    faPen,
    faArrowUp,
    faCheckCircle,
    faCog,
    faCogs,
    faEye,
);


export const Icons = [
    'coffee',
    'snowflake',
    'archive',
];

// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/premium-themes/paperbase/Paperbase.js
export let theme = createTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
theme = {
    ...theme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#081627',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: theme.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255,255,255,0.15)',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4fc3f7',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            minWidth: 'auto',
            marginRight: theme.spacing(2),
            '& svg': {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
    },
};

const drawerWidth = 256;

export const Styles = {
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
    footer: {
        padding: theme.spacing(2),
        background: '#eaeff1',
    },
    modal: {
        body: {
            marginTop: '10%',
        },
        card: {

        },
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    palette: {
      info: {
        main: theme.palette.info.main,
      }
    }
};



const IconMap = {
    // Misc
    [ChonkyIconName.loading]: CachedIcon,
    [ChonkyIconName.dropdown]: ArrowDropDownRoundedIcon,
    [ChonkyIconName.placeholder]: RemoveRoundedIcon,

    // File Actions: Drag & drop
    [ChonkyIconName.dndDragging]: PanToolRoundedIcon,
    [ChonkyIconName.dndCanDrop]: ArrowDownwardRoundedIcon,
    [ChonkyIconName.dndCannotDrop]: ClearRoundedIcon,

    // File Actions: File operations
    [ChonkyIconName.openFiles]: FolderOpenRoundedIcon,
    [ChonkyIconName.openParentFolder]: ArrowUpwardRoundedIcon,
    [ChonkyIconName.copy]: CopyAllRoundedIcon,
    [ChonkyIconName.paste]: ContentPasteRoundedIcon,
    [ChonkyIconName.share]: IosShareRoundedIcon,
    [ChonkyIconName.search]: SearchRoundedIcon,
    [ChonkyIconName.selectAllFiles]: SelectAllRoundedIcon,
    [ChonkyIconName.clearSelection]: LayersClearRoundedIcon,

    // File Actions: Sorting & options
    [ChonkyIconName.sortAsc]: SortByAlphaRoundedIcon,
    [ChonkyIconName.sortDesc]: SortByAlphaRoundedIcon,
    [ChonkyIconName.toggleOn]: ToggleOnRoundedIcon,
    [ChonkyIconName.toggleOff]: ToggleOffRoundedIcon,

    // File Actions: File Views
    [ChonkyIconName.list]: FormatListBulletedRoundedIcon,
    [ChonkyIconName.compact]: ListRoundedIcon,
    [ChonkyIconName.smallThumbnail]: CropOriginalRoundedIcon,
    [ChonkyIconName.largeThumbnail]: InsertPhotoRoundedIcon,

    // File Actions: Unsorted
    [ChonkyIconName.folder]: FolderIcon,
    [ChonkyIconName.folderCreate]: CreateNewFolderRoundedIcon,
    [ChonkyIconName.folderOpen]: FolderOpenRoundedIcon,
    [ChonkyIconName.folderChainSeparator]: KeyboardArrowRightRoundedIcon,
    [ChonkyIconName.download]: DownloadRoundedIcon,
    [ChonkyIconName.upload]: UploadRoundedIcon,
    [ChonkyIconName.trash]: DeleteForeverRoundedIcon,
    [ChonkyIconName.fallbackIcon]: ReportProblemRoundedIcon,

    // File modifiers
    [ChonkyIconName.symlink]: LaunchRoundedIcon,
    [ChonkyIconName.hidden]: VisibilityOffRoundedIcon,

    // Generic file types
    [ChonkyIconName.file]: InsertDriveFileRoundedIcon,
    [ChonkyIconName.license]: FolderIcon,
    [ChonkyIconName.code]: CodeRoundedIcon,
    [ChonkyIconName.config]: SettingsRoundedIcon,
    [ChonkyIconName.model]: FolderIcon,
    [ChonkyIconName.database]: FolderIcon,
    [ChonkyIconName.text]: FolderIcon,
    [ChonkyIconName.archive]: FolderIcon,
    [ChonkyIconName.image]: FolderIcon,
    [ChonkyIconName.video]: FolderIcon,
    [ChonkyIconName.info]: FolderIcon,
    [ChonkyIconName.key]: FolderIcon,
    [ChonkyIconName.lock]: FolderIcon,
    [ChonkyIconName.music]: FolderIcon,
    [ChonkyIconName.terminal]: FolderIcon,
    [ChonkyIconName.users]: FolderIcon,

    // OS file types
    [ChonkyIconName.linux]: FolderIcon,
    [ChonkyIconName.ubuntu]: FolderIcon,
    [ChonkyIconName.windows]: FolderIcon,

    // Programming language file types
    [ChonkyIconName.rust]: FolderIcon,
    [ChonkyIconName.python]: FolderIcon,
    [ChonkyIconName.nodejs]: FolderIcon,
    [ChonkyIconName.php]: FolderIcon,

    // Development tools file types
    [ChonkyIconName.git]: FolderIcon,

    // Brands file types
    [ChonkyIconName.adobe]: FolderIcon,

    // Other program file types
    [ChonkyIconName.pdf]: FolderIcon,
    [ChonkyIconName.excel]: FolderIcon,
    [ChonkyIconName.word]: FolderIcon,
    [ChonkyIconName.flash]: FolderIcon,
}
export const MUIIcons = React.memo((props) => {
    const { icon } = props;

    let TheIcon // FontAwesome `IconDefinition`
    if (typeof TheIcon === 'object') TheIcon = icon;
    else TheIcon = IconMap[icon] ?? IconMap.fallbackIcon;

    return <TheIcon {...props} />
});

setChonkyDefaults({
    iconComponent: ChonkyIconFA,
    darkMode: false,
});
