import { Router } from '@reach/router';
import AdminContext from "components/admin/AdminContext";
import Namespace from "components/admin/Namespace";
import Namespaces from "components/admin/Namespaces";
import BrowserHelpPage from "components/browser/BrowserHelpPage";
import BrowserPage from "components/browser/BrowserPage";
import HomePage from "components/HomePage";
import Layout from 'components/layout/Layout';
import LoginPage from "components/LoginPage";
import LogoutPage from "components/LogoutPage";
import React from 'react';
import { wrapper } from 'utils';



const mapStateToProps = (state) => ({
    authToken: state.login.authToken,
});

const mapDispatchToProps = {
};

// export default wrapper(AppRouter, {
//     conn: [mapStateToProps, mapDispatchToProps],
//     style: 'navbar',
// });
// const _ProtectedRoute = ({ component: Component, login, ...rest }) => (
//     login.token ? <Component {...rest} /> : <Redirect from="" to="login" noThrow />
// );
// const ProtectedRoute = wrapper(_ProtectedRoute, [mapStateToProps]);
//
// const PublicRoute = ({ component: Component, ...rest }) => (
//     <Component {...rest} />
// );

// const Browse = () => (
//         <Router>
//             <BrowsingContext path=":pth" />
//         </Router>
// )

class AppRouter extends React.Component {
    render() {
        return (
            <Router>
                <Layout path='/'>
                    <HomePage path='/' />
                    <LoginPage path='login'/>
                    <LogoutPage path='logout'/>
                    <BrowserPage path='browse/n/:hashid/*' />
                    <BrowserHelpPage path='browse/h/:hashid/*' />
                    <AdminContext path='admin'>
                        <Namespaces path='namespaces' />
                        <Namespace path="namespaces/:namespaceId/*" />
                    </AdminContext>
                </Layout>
            </Router>
        );

    }
}


export default wrapper(AppRouter, {
    conn: [mapStateToProps, mapDispatchToProps],
    style: 'navbar',
});

