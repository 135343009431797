import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { login } from 'actions/auth';
import { SubmitFormButton } from 'components/button';
import Box from '@mui/material/Box';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { l_error } from 'utils';
import * as Yup from 'yup';
import { wrapper } from "../../utils";
const err = l_error('LOGINFORM');

const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

const mapStateToProps = (state) => ({
    login: state.login,
});

const mapDispatchToProps = {
    login,
};

const styles = (theme) => ({
});

const _LoginForm = ({login, handleSuccess}) => {
   return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, actions) => {
                login(values)
                    .then((data) => {
                        actions.setSubmitting(false);
                    })
                    .catch((error) => {
                        actions.setSubmitting(false);
                        err(error);
                        if(error.error && error.error.non_field_errors){
                            err(error.error.non_field_errors[0]);
                            actions.setStatus(error.error.non_field_errors[0]);
                        } else {
                            actions.setStatus(JSON.stringify(error.error));
                        }
                        err(error);
                    })
                    .then(handleSuccess)
            }}>
            {formik => (
                    <Form>
                        <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: '2rem' }}>
                            Sign in
                        </Typography>
                        {formik.status &&
                            <FormLabel error={formik.status && true}>{formik.status}</FormLabel>
                        }
                        <div style={{ marginBottom: '2rem' }}>
                            <FormGroup>
                                <TextField placeholder="username"
                                       name="username"
                                       type='text'
                                       label="Username"
                                       value={formik.values.username}
                                       onChange={formik.handleChange}
                                       error={formik.errors.username && true}
                                       fullWidth autoFocus
                                />
                            </FormGroup>
                            <ErrorMessage name="username" />
                        </div>
                        <Box sx={{ mb: 2 }}></Box>
                        <div>
                            <FormGroup>
                                <TextField name="password"
                                           type='password'
                                           label="Password"
                                           value={formik.values.password}
                                           onChange={formik.handleChange}
                                           error={formik.errors.password && true}
                                           fullWidth
                                />
                            </FormGroup>
                            <ErrorMessage name="password" />
                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <SubmitFormButton 
                                disabled={formik.isSubmitting} 
                                sx={{
                                    marginTop: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1
                                }}
                            >
                                <FontAwesomeIcon icon={formik.isSubmitting ? "spinner" : "sign-in-alt"} spin={formik.isSubmitting} />
                                <Typography>{formik.isSubmitting ? "Signing in..." : "Sign in"}</Typography>
                            </SubmitFormButton>
                        </Box>
                    </Form>
            )}
        </Formik>
    )
};

export const LoginForm = wrapper(_LoginForm, {
    conn: [mapStateToProps, mapDispatchToProps],
    style: styles});
