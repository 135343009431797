import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { create } from 'actions/backendModel';
import { SubmitFormButton } from 'components/button';
import { success } from 'actions/notifier';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { shareEmail } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";
import * as Yup from 'yup';
import { configuration } from 'configuration';

const MailSchema = Yup.object().shape({
    id: Yup.number(),
    recipient: Yup.string().email('Invalid email').required('Required'),
});

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    create, success 
};

const styles = (theme) => ({
});

const _Form = ({ create, success, handleSuccess, link }) => {
    let accessUrl = `${configuration.BACKEND_SERVER_URI}/browse/n/${link.hashid}`;
    let initialValues = {
        recipient: '',
    };
    let actionFn = create;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={MailSchema}
            onSubmit={(values, actions) => {
                let payload = values;
                payload['access_url'] = accessUrl;
                payload['hashid'] = link.hashid;
                actionFn(shareEmail, payload)
                    .then(() => {
                        actions.setSubmitting(false);
                        success('Email sent successfully');
                    })
                    .catch((error) => {
                        actions.setSubmitting(false);
                        if (error.error && error.error.non_field_errors){
                            actions.setStatus(error.error.non_field_errors[0]);
                        } else {
                            actions.setStatus(JSON.stringify(error.error));
                        }
                    })
                    .finally(handleSuccess)
            }}>
            {formik => (
                <Form>
                    {link && 
                        <Field type='hidden' name="id" value={link.id} />
                    }
                    {formik.status &&
                        <FormLabel error={formik.status && true}>{formik.status}</FormLabel>
                    }
                    <br/>
                    <div>
                        <FormGroup>
                            <TextField
                                id="recipient"
                                name="recipient"
                                label="Recipient"
                                placeholder='recipient@example.com'
                                value={formik.values.recipient}
                                onChange={formik.handleChange}
                                helperText="The email of the person who will receive the access link and password"
                                error={formik.errors.recipient && true}
                            />
                        </FormGroup>
                        <ErrorMessage name="recipient" />
                    </div>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <SubmitFormButton disabled={formik.isSubmitting || formik.errors.recipient}>
                            <Typography>Submit</Typography>
                            {formik.isSubmitting && <FontAwesomeIcon icon="snowflake" spin />}
                        </SubmitFormButton>
                        <Button variant="contained" color="secondary" onClick={handleSuccess}>Cancel</Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
};

export const MailForm = wrapper(_Form, {
    conn: [mapStateToProps, mapDispatchToProps],
    style: styles});
