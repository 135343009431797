import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccessLinkEvents from 'components/admin/AccessLinkEvents';
import { BACKEND_ENDPOINTS } from "configuration";
import * as React from 'react';
import { wrapper } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


class EventDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose(){
        this.props.onHandleClose();
    }  
    render(){
        const {link, open} = this.props;
        if(!link){
            return null;
        }
        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={this.handleClose}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Action log
                        </Typography>
                    </Toolbar>
                </AppBar>
                <AccessLinkEvents link={link} />
                <DialogActions>
                    Only last 20 events are exported here.
                </DialogActions>
          </Dialog>
        )
    }
}

export default wrapper(EventDialog, {
    conn: [null, null],
    isAdmin: true,
});
