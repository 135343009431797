import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';

const Imguni = styled.img`
  width: 6em;
`;

const Imglcsb = styled.img`
  width: 5em;
`;


function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright ©'}
        <Link color="inherit" href="https://wwwen.uni.lu/">
          Université du Luxembourg
        </Link>{' '}
        {new Date().getFullYear()}.
        All rights reserved.
      </Typography>
    );
  }
  

  function Version() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        Version: {process.env.REACT_APP_VERSION}
      </Typography>
    );
  }

  


class Footer extends React.Component {
    render() {
        return (
            <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }} align='center'>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <a href="https://wwwen.uni.lu/">
                        <img src={"/" + process.env.PUBLIC_URL + "logos.png"} alt="logos"/>
                    </a>
                </Grid>
                <Copyright />
                <Version />
            </Box>
            
            // <Grid container direction="row"
            //     justify="center"
            //     alignItems="center">
            //     <Grid item xs={6} sm={4}>
            //         <a href="http://www.uni.lu">
            //             <Imguni src={ulLogo} />
            //         </a>
            //         <a href="http://lcsb.uni.lu">
            //             <Imglcsb src={lcsbLogo} />
            //         </a>
            //     </Grid>
            //     <Grid item xs={6} sm={8}>
            //         <span className="text-muted">Copyright © <a href="http://www.uni.lu">Université du Luxembourg</a> 2021. All rights reserved.</span>
            //     </Grid>
            // </Grid>
        );
    }
}

export default Footer;


