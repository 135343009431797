import { l_debug, l_error } from 'utils';
const err = l_error('REQUESTS');
const dbg = l_debug('REQUESTS');

function getErrorFromResponse(response){
    try {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        } else {
            return response.text()
        }
     } catch (e) {
        console.log('catch')
        return response.data && response.data.message || response.statusText || response;
    }
}

function getDataFromResponse(response){
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json()
    } else {
        return response.text()
    }
}


function handleErrors(response){
    if (!response.ok){
        throw response;
    }
    return response
}

function unpackResponse(response) {
    return new Promise(function(resolve, reject) {
        switch (response.status) {
            case 204: // no content
                resolve({status: response.status});
                break;
            case 200:
            case 201:
                getDataFromResponse(response)
                    .then((data) => resolve({status: response.status, data: data}))
                    .catch((e) => {
                        err('Error when getting data from response');
                        err(e);
                        reject(e);
                    });
                break;
            default:
                err(response);
                reject(response);
        }
    });
}



function unpackError(response) {
    return new Promise(function(resolve, reject) {
        switch (response.status) {
            case 400:
            case 401: 
            case 403:
            case 404:
            case 405:
            case 500:
                getErrorFromResponse(response)
                    .then((data) => {
                        let msg = `${response.status}`
                        if(data.detail){
                            msg = `${response.status}: ${data.detail}`
                        } else if(data.error && data.error.reason) {
                            msg = `${response.status}: ${data.error.reason}`
                        } else if(data.non_field_errors) {
                            msg = `${response.status}: ${data.non_field_errors[0]}`
                        }
                        reject(msg);
                    }
                        )
                    .catch((e) => {
                        err('Error when getting error from response');
                        err(e);
                        reject(e);
                    });
                break;
            default:
                err('Issue with the server.')
                err(response);
                reject(JSON.stringify(response));
        }
    });
}


export function makeBackendRequest(url, requestData, login) {

    // auth
    if (login && login.token) {
        requestData.headers = {
            ...requestData.headers,
                'Authorization': `Token ${login.token}`,
        };
    }
    requestData = {
        ...requestData,
        mode: 'cors',
        headers: {
            ...requestData.headers,
        }
    }
    console.log('makeBackendRequest', url ,requestData, login)
    return fetch(url, requestData)
        .then(handleErrors)
        .then(unpackResponse)
        .catch(unpackError)
}

export function makeAsperaRequest(url, requestData, authHeaders){
    // auth
    requestData.headers = {
        ...requestData.headers,
        ...authHeaders,
    };
    return fetch(url, requestData)
        .then(handleErrors)
        .then(unpackResponse)
        .catch(unpackError)
}

