import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormLabel, Paper, Container, Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { readWithAuth } from 'actions/backendModel';
import { SubmitFormButton } from 'components/button';
import { ErrorMessage, Form, Formik } from 'formik';
import { userLink } from "model/appObject";
import React from 'react';
import { l_error } from 'utils';
import * as Yup from 'yup';
import { wrapper } from "../../utils";
const err = l_error('LOGINFORM');

const SimpleSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
});

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    readWithAuth,
};

const styles = (theme) => ({
});

const _Form = ({login, hashid, readWithAuth, handleSuccess}) => {
   return (
        <Formik
            initialValues={{
                password: '',
            }}
            validationSchema={SimpleSchema}
            onSubmit={(values, actions) => {
                readWithAuth(userLink, values.password, hashid)
                    .then((data) => {
                        actions.setSubmitting(false);
                        handleSuccess(values.password);
                    })
                    .catch((error) => {
                        actions.setSubmitting(false);
                        if(error === "401" ){
                            actions.setErrors({password: "wrong credentials"});
                        } else if(error.error && error.error.non_field_errors){
                            err(error.error.non_field_errors[0]);
                            actions.setStatus(error.error.non_field_errors[0]);
                        } else {

                            switch (error.status){
                                case 404:
                                    actions.setStatus("Link does not exists or has been deleted.");
                                    break;
                                case 401:
                                    actions.setStatus("Wrong credentials.");
                                    break;
                            default:
                                actions.setStatus(JSON.stringify(error.error));
                            }
                        }
                        err(error);
                    })
            }}>
            {formik => (
                <Container maxWidth="sm" sx={{ mt: 4 }}>
                    <Paper elevation={3} sx={{ py: 2, px: -3}}>
                        <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h4" align="center" gutterBottom>
                                Access Link
                            </Typography>
                            {formik.status &&
                                <FormLabel error={formik.status && true}>{formik.status}</FormLabel>
                            }
                            <div style={{ width: '100%', maxWidth: '400px', marginTop: 2 }}>
                                <FormGroup>
                                    <TextField placeholder="password"
                                           name="password"
                                           type='password'
                                           label="Password"
                                           value={formik.values.password}
                                           onChange={formik.handleChange}
                                           error={formik.errors.password && true}
                                           fullWidth autoFocus
                                    />
                                </FormGroup>
                                <ErrorMessage name="password" >
                                    {message => <div style={{ color: '#d32f2f' }}>{message}</div> }
                                </ErrorMessage>
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <SubmitFormButton 
                                    disabled={formik.isSubmitting} 
                                    sx={{
                                        marginTop: 3,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 1
                                    }}
                                >
                                    <FontAwesomeIcon icon={formik.isSubmitting ? "spinner" : "sign-in-alt"} spin={formik.isSubmitting} />
                                    <Typography>{formik.isSubmitting ? "Submitting..." : "Enter"}</Typography>
                                </SubmitFormButton>
                            </Box>
                        </Form>
                    </Paper>
                </Container>
            )}
        </Formik>
    )
};

export const AccessLinkPasswordForm = wrapper(_Form, {
    conn: [mapStateToProps, mapDispatchToProps],
    style: styles});
