import {Paper, Box, Card, CardContent, Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { list } from "actions/backendModel";
import { adminLink } from "model/appObject";
import React from 'react';
import { wrapper } from "utils";
import AccessLinkTableItem from "./AccessLinkTableItem";

const mapStateToProps = (state) => ({
    links: state.objects.links,
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
});

const mapDispatchToProps = {
    list,
};

class AccessLinkTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLinks: [],
        }
    }

    componentDidMount() {
        const {selectedNamespaceID, selectedShareName} = this.props;
        const {list} = this.props;
        list(adminLink, selectedNamespaceID, selectedShareName)
            .then((data) => {
            })
            .catch((error) => {
                console.error('list adminLink error');
                console.error(error);
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {links, selectedShareName} = this.props;
        if(!links){
            return;
        }
        if (links !== prevProps.links){
            this.setState(
                {currentLinks: Object.keys(links).filter((k) => links[k].share.name === selectedShareName)}
            )
        }
    }

    render() {
        const {links, hashid} = this.props;
        const {currentLinks} = this.state;
        if(!currentLinks || currentLinks.length === 0){
            return (
                <Box sx={{ width: '100%' }}>
                    <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                        <CardContent sx={{ p: 2 }}>
                            <Typography variant="body1" color="text.secondary" align="center">
                                No links available for this share
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            );
        }
        const sortedLinks = currentLinks
            .filter(key => links[key] && links[key].created_date) // Ensure the link exists and has a created_date
            .sort((k1, k2) => new Date(links[k1].created_date) - new Date(links[k2].created_date));
        
        const highlightedLinkIndex = sortedLinks.findIndex(key => links[key] && links[key].hashid === hashid);
        if (highlightedLinkIndex !== -1) {
            sortedLinks.splice(0, 0, ...sortedLinks.splice(highlightedLinkIndex, 1));
        }
        //Download	Upload	Access levels	Link	Access password	Encryption password	Expiration date	Refresh	Edit	Delete
        const Links = sortedLinks.map((key) => (
            <AccessLinkTableItem
                key={key}
                link={links[key]}
                isHighlighted={links[key].hashid === hashid}
            />
        ));

        return (
            <TableContainer 
                component={Paper} 
                sx={{ 
                    boxShadow: 0, 
                    borderRadius: 0, 
                    overflow: 'auto',
                    maxHeight: 'calc(63vh - 100px)',
                }}
            >
                <Table aria-label="access link table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Download</TableCell>
                            <TableCell>Upload</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>Access password</TableCell>
                            <TableCell>Expiration date</TableCell>
                            <TableCell>Actions</TableCell>
                            <TableCell>Metadata</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Links}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default wrapper(AccessLinkTable, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
});
