import { navigate } from "@reach/router";
import { logout } from "actions/auth";
import React from 'react';
import { wrapper } from 'utils';

const mapDispatchToProps = {
    logout,
};

const styles = (theme) => ({
    paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 16px',
    },
});

class LogoutPage extends React.Component {

    componentDidMount(){
        const {logout} = this.props;
        logout().finally(navigate('/'))
    }


    render() {

        return (
            <p>Logout</p>
        );
    }
}



export default wrapper(LogoutPage, {
    conn: [null, mapDispatchToProps],
});
