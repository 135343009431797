import React from 'react';
import { Dialog, DialogContent, TextField, Button, Grid, DialogTitle } from '@mui/material';
import { wrapper } from "utils";
import { SubmitFormButton } from 'components/button';

const mapStateToProps = (state) => ({
    selectedShareName: state.admin.selectedShareName,
    selectedNamespaceID: state.admin.selectedNamespaceID,
    token: state.login.token,
});

class ShareModal extends React.Component {
    state = {
        description: this.props.shareDescription || '',
    };

    updateShare = async () => {
        const { token, selectedNamespaceID, shareName } = this.props;
        const { description } = this.state;

        try {
            const response = await fetch(`/api/namespaces/${selectedNamespaceID}/shares/update_description/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ namespace: selectedNamespaceID, name: shareName, description }),
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleSave = async () => {
        await this.updateShare();
        this.props.share.description = this.state.description;
        this.props.handleSuccess();
    }

    handleChange = (event) => {
        this.setState({ description: event.target.value });
    }

    handleCancel = () => {
        this.setState({ description: this.props.shareDescription || '' });
        this.props.handleSuccess();
    }

    render() {
        const { showModal } = this.props;
        const { description } = this.state;

        return (
            <Dialog
                open={showModal}
                onClose={this.handleCancel}
                aria-labelledby="edit-share-description"
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>
                    Change a share description
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Share description"
                        type="text"
                        fullWidth
                        value={description}
                        onChange={this.handleChange}
                        inputProps={{ maxLength: 20 }}
                        helperText={`${description ? description.length : 0}/20 characters`}
                        variant="outlined"
                    />
                    <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid item>
                            <SubmitFormButton 
                                onClick={this.handleSave} 
                                disabled={description === this.props.shareDescription}
                            >
                                Submit
                            </SubmitFormButton>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={this.handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
}

export default wrapper(ShareModal, {
    conn: [mapStateToProps],
    isAdmin: true,
    style: true,
});