import Button from '@mui/material/Button';
import React from "react";


export const SubmitFormButton = ({children, ...xtra}) => (
    <Button variant="contained" color="primary" type='submit' {...xtra}>
        {children}
    </Button>
);


