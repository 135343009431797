import React from 'react';
import { Link } from '@reach/router';

import {
    Paper, 
    Typography, 
    Grid, 
    Button, 
    Box,
    Chip, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
    Card,
    CardContent,
    Divider
} from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';

import { list } from "actions/backendModel";
import SearchLinkModal from "components/admin/SearchLinkModal";
import { namespace } from "model/appObject";
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    namespaces: state.objects.namespaces,
});

const mapDispatchToProps = {
    list,
};


class Namespaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchModal: false
        };
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleOpenSearchModal = this.handleOpenSearchModal.bind(this);
        this.handleCloseSearchModal = this.handleCloseSearchModal.bind(this);
    }

    componentDidMount() {
        const {list} = this.props;
        list(namespace)
            .then((data) => {
            })
            .catch((error) => {
            })
    }

    renderItem(item){
        const link = "/admin/namespaces/" + item.pk;
        return (
            <ListItem disablePadding key={item.pk}>
                <ListItemIcon><FolderIcon /></ListItemIcon>
                <ListItemText>
                    <Link to={link}>{item.name}</Link>
                </ListItemText>
            </ListItem> 

            );
    }

    handleSearchClick() {
        this.handleOpenSearchModal();
    }

    handleOpenSearchModal() {
        this.setState({ showSearchModal: true });
    }

    handleCloseSearchModal() {
        this.setState({ showSearchModal: false });
    }

    render() {
        const {namespaces} = this.props;
        const {showSearchModal} = this.state;
        
        return (
            <Grid item xs={12} md={6} sx={{ mt: 0 }}>
                <SearchLinkModal open={showSearchModal} onClose={this.handleCloseSearchModal} onOpen={this.handleOpenSearchModal} />
                <Box sx={{ width: '100%', mb: 2 }}>
                    <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                        <CardContent sx={{ p:2, mb: 0 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h6" color="text.primary">
                                        Can't find your share?
                                    </Typography>
                                    <Button 
                                        variant="contained"
                                        color="primary" 
                                        size="small" 
                                        startIcon={<SearchIcon />}
                                        onClick={this.handleSearchClick}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="text.secondary">
                                        Quickly locate your share or access link using the search feature. <br />
                                        Click the "Search" button above or press <Chip label="S" size="small" color="primary" /> to begin.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ my: -2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Typography variant="caption" color="text.secondary">
                                        Press <Chip label="Esc" size="small" color="default" /> to close the search modal
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
                <Card variant="outlined" sx={{ boxShadow: 0, borderRadius: 0 }}>
                    <CardContent sx={{ p: 2 }}>
                        {!namespaces ? (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="error">
                                        You don't have access to any namespace
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="text.secondary">
                                        Please contact your administrator for access.
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="text.primary">
                                        Namespaces
                                    </Typography>
                                    <Divider sx={{ my: 1 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <List>
                                        {Object.keys(namespaces).map((key) => this.renderItem(namespaces[key]))}
                                    </List>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
                
            </Grid>
        );
    }
}

export default wrapper(Namespaces, {
    conn: [mapStateToProps, mapDispatchToProps],
    isAdmin: true,
    style: true,
});
