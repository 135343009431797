import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { navigate } from "@reach/router";
import { LoginForm } from 'components/forms/LoginForm';
import React from 'react';

export default class LoginPage extends React.Component {

    constructor(){
        super();
        this.handleSuccess = this.handleSuccess.bind(this)
    }

    handleSuccess(data){
        navigate('/admin/namespaces')
    }

    render() {
        return (
            <Paper
                variant="outlined"
                sx={{
                    maxWidth: 400,
                    margin: 'auto',
                    padding: 4,
                }}
            >
                <Grid container spacing={9} direction="column" alignItems="center" sx={{borderRadius: 0}}>
                    <Grid item xs={12} width="100%">
                        <LoginForm handleSuccess={this.handleSuccess} />
                    </Grid>
                    
                </Grid>
            </Paper>
        );
    }
}


