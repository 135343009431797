import { browse } from 'actions/aspera';
import { notifier } from "actions/notifier";
import { Actions, configuration, Events, LOCAL_STORAGE, SAGAS } from 'configuration';
import { makeAsperaRequest, makeBackendRequest } from 'model/request';
import { normalizeData } from 'model/schema';
import { getAuthHeaders, getConnect, getCurrentLink, getLogin } from 'model/selectors';
import { actionChannel, all, call, debounce, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';
import { l_debug } from 'utils';
const dbg = l_debug('SAGAS');


export function* asperaBrowserRequest() {
    const {type, url, fileId, schema, requestData, resolve, reject, errorMessage} = yield take([
        Actions.ASPERA.BROWSE, Actions.ASPERA.MKDIR, Actions.ASPERA.DELETE,
    ]);
    yield put({type: SAGAS.REQUEST(type), fileId: fileId});
    const authHeaders = yield select(getAuthHeaders);
    try {
        let result = yield call(makeAsperaRequest, url, requestData, authHeaders);
        if(schema){
            result = normalizeData(result.data, schema);
        }
        yield put({type: SAGAS.SUCCESS(type), fileId: fileId, result});
        resolve(result);
    } catch (e) {
        yield put({type: SAGAS.FAILURE(type), fileId: fileId, error: errorMessage || e});
        if(errorMessage){
            yield put(notifier.error(errorMessage));
        } else {
            yield put(notifier.error(e));
        }
        reject(e)
    }

}



export function* backendObjectRequest(){
    const {type, model, url, requestData, origin, resolve, reject} = yield take([
        Actions.BACKEND.MODEL(Events.CREATE),
        Actions.BACKEND.MODEL(Events.READ),
        Actions.BACKEND.MODEL(Events.UPDATE),
        Actions.BACKEND.MODEL(Events.DELETE),
        Actions.BACKEND.MODEL(Events.LIST),
        Actions.BACKEND.MODEL(Events.SEARCH),
        Actions.BACKEND.CONTACT_OWNERS,
    ]);
    yield put({type: SAGAS.REQUEST(type)});
    const login = yield select(getLogin);
    try {
        let result = yield call(makeBackendRequest, url, requestData, login);
        if(model){
            result = model.normalize(type, result.data);
        }
        yield put({type: SAGAS.SUCCESS(type), model, result, origin: origin});
        resolve(result);
    } catch (e) {
        yield put({type: SAGAS.FAILURE(type)});
        // logout if token is expired
        if(e.status === 401 || new Date(login.expiry) < new Date() || e.startsWith('401: ')){
            yield put({type: SAGAS.SUCCESS(Actions.BACKEND.LOGOUT)});
        } 
        yield put(notifier.error(e));
        reject(e)
    }
}

export function* authRequest(){
    const {type, url, requestData, resolve, reject} = yield take([
        Actions.BACKEND.LOGIN, Actions.BACKEND.LOGOUT
    ]);
   
    yield put({type: SAGAS.REQUEST(type)});

    switch (type) {
        case Actions.BACKEND.LOGIN:
            try {
                // add credentials include header
                const req = {...{credentials: 'include', ...requestData}};
                const result = yield call(makeBackendRequest, url, req);
                yield put({type: SAGAS.SUCCESS(type), result});
                localStorage.setItem(LOCAL_STORAGE.LOGIN, JSON.stringify(result.data));
                yield put(notifier.success("Login successful"));
                resolve(result);
            } catch (e) {
                yield put({type: SAGAS.FAILURE(type), e});
                yield put(notifier.error(e));
                reject(e);
            }
            break;
        case Actions.BACKEND.LOGOUT:
            const login = yield select(getLogin);
            try {
                const result = yield call(makeBackendRequest, url, requestData, login);
                yield put({type: SAGAS.SUCCESS(type), result});
                localStorage.removeItem(LOCAL_STORAGE.LOGIN);
                yield put(notifier.success("Logout successful"));
                resolve(result);
            } catch (e) {
                yield put({type: SAGAS.FAILURE(type), e});
                yield put(notifier.error(e));
                reject(e);
            }
            break;
        default:
            const msg = 'Saga action not recognized properly.';
            yield put({type: SAGAS.FAILURE(type), msg});
            reject(msg);
    }
}

export function* navigate(){
    const {type, fileId, name, resolve, reject} = yield take([Actions.ASPERA.BROWSER_INIT, Actions.BROWSER.NAVIGATE]);
    yield put({type: SAGAS.REQUEST(type)});
    try {
        const result = yield putResolve(browse({fileId}));
        yield put({type: SAGAS.SUCCESS(type), result, name: name, fileId: fileId});
        resolve(result);
    } catch (e) {
        yield put({type: SAGAS.FAILURE(type), name: name, fileId: fileId});
        reject(e);
    }
}


export function* asperaUpload() {
    const {type, path, files, resolve, reject} = yield take(Actions.ASPERA.UPLOAD);
    //const authHeaders = yield select(getAuthHeaders);
    const transferFiles = getTransferFilesToUpload(path, files);
    let link = yield select(getCurrentLink);
    const payload = getTransferPayload(Events.ASPERA.UPLOAD, link, transferFiles);
    const connect = yield select(getConnect);
    yield put({type: SAGAS.REQUEST(type),
        path, files, transferFiles, payload, connect, link
    });
    try {
        const result = yield call([connect, connect.startTransfer],
            payload,
            {allow_dialogs: false, return_paths: false,},
        );
        if ('error' in result){
            reject(result.error);
            yield put({type: SAGAS.FAILURE(type), e: result.error});
        } else {
            connect.showTransferManager();
            yield put({type: SAGAS.SUCCESS(type)});
            resolve();
        }
    } catch (e) {
        yield put({type: SAGAS.FAILURE(type), e: e});
        yield put(notifier.error(e));
        reject(e);
    }
}

// upload files
export function* asperaDownload(){
    const {type, path, files, resolve, reject} = yield take(Actions.ASPERA.DOWNLOAD);
    const transferFiles = getTransferFilesToDownload(path, files);
    let link = yield select(getCurrentLink);
    const payload = getTransferPayload(Events.ASPERA.DOWNLOAD, link, transferFiles);
    const connect = yield select(getConnect);

    try {
        const result = yield call([connect, connect.startTransfer],
            payload,
            {allow_dialogs: false, return_paths: false,},
        );
        if ('error' in result){
            reject(result.error);
            yield put({type: SAGAS.FAILURE(type), e: result.error});
        } else {
            connect.showTransferManager();
            yield put({type: SAGAS.SUCCESS(type)});
            resolve();
        }
    } catch (e) {
        yield put({type: SAGAS.FAILURE(type), e: e});
        yield put(notifier.error(e));
        reject(e);
    }
}

// parse file name from the fileselector
function parseFileName(path){
    if (/Win/.test(navigator.platform)) {
        return path.substring(path.lastIndexOf('\\') + 1);
    } else {
        return path.substring(path.lastIndexOf('/') + 1);
    }
}

function getTransferFilesToUpload(path, files){
    return files
        .filter((file) => typeof file.name === 'string')
        .map((file) => {
            const filename = file.name;
            const parsed = parseFileName(filename);
            return {
                "source": filename,
                "destination": `${path}/${parsed}`,
            }
        });
}

function getTransferFilesToDownload(path, files){
    return files
        .filter((file) => typeof file.name === 'string')
        .map((file) => {
            const filename = file.name;
            return {
                "source": `${path}/${filename}`,
            }
        });
}

export function getTransferPayload(direction, link, transferFiles){
    let payload = {
        paths: transferFiles,
        remote_host: configuration.ASPERA.HOST,
        remote_user: link.remote_user,
        lock_target_rate: configuration.ASPERA.LOCK_TARGET_RATE,
        lock_rate_policy: configuration.ASPERA.LOCK_RATE_POLICY,
        authentication: configuration.ASPERA.AUTHENTICATION,
        ssh_port: configuration.ASPERA.SSH_PORT,
        http_fallback: configuration.ASPERA.FALLBACK,
        http_fallback_port: configuration.ASPERA.FALLBACK_PORT,
        direction: direction,
        create_dir: configuration.ASPERA.CREATE_DIR,
        destination_root: configuration.ASPERA.DESTINATION_ROOT,
    };

    if(direction === Events.ASPERA.DOWNLOAD){
        payload.token = link.download_token;
    } else if(direction === Events.ASPERA.UPLOAD) {
        payload.token = link.upload_token;
        if(link.content_protection_password){
            payload.content_protection = configuration.ASPERA.CONTENT_PROTECTION_METHOD;
            payload.content_protection_passphrase = link.content_protection_password;
        }
    } else {
        console.error(`Not valid 'direction' parameter for transfer_spec. It should be "${Events.ASPERA.DOWNLOAD}" or "${Events.ASPERA.UPLOAD}"`, direction);
    }
    return payload;
}

/**
 * SAGA LISTENERS
 */
export function* backendObjectRequestListener() {
    yield takeEvery(
        [
            Actions.BACKEND.MODEL(Events.CREATE),
            Actions.BACKEND.MODEL(Events.READ),
            Actions.BACKEND.MODEL(Events.UPDATE),
            Actions.BACKEND.MODEL(Events.DELETE),
            Actions.BACKEND.MODEL(Events.LIST),
            Actions.BACKEND.MODEL(Events.SEARCH),
            Actions.BACKEND.CONTACT_OWNERS,
        ], backendObjectRequest);
}


export function* debounceRequest(action){
    action.resolve(action.fn(...action.args));
}

export function* debounceListener() {
    yield debounce(1000, Actions.DEBOUNCE, debounceRequest);
}

function* authRequestsListener() {
    const authChannel = yield actionChannel(
        [Actions.BACKEND.LOGIN, Actions.BACKEND.LOGOUT]
    );
    yield takeEvery(authChannel, authRequest);

}

export function* asperaBrowserRequestListener() {
    const browseChannel = yield actionChannel([Actions.ASPERA.BROWSE, Actions.ASPERA.MKDIR, Actions.ASPERA.DELETE]);
    yield takeEvery(browseChannel, asperaBrowserRequest);
}


export function* asperaUploadListener() {
    yield takeEvery(Actions.ASPERA.UPLOAD, asperaUpload);
}

export function* asperaDownloadListener() {
    yield takeEvery(Actions.ASPERA.DOWNLOAD, asperaDownload);
}
export function* navigatelistener() {
    const navChannel = yield actionChannel([Actions.ASPERA.BROWSER_INIT, Actions.BROWSER.NAVIGATE]);
    yield takeEvery(navChannel, navigate);
}

export default function* rootSaga() {
    yield all([
        backendObjectRequestListener(),
        backendObjectRequest(),
        asperaBrowserRequest(),
        asperaBrowserRequestListener(),
        asperaDownload(),
        asperaDownloadListener(),
        asperaUpload(),
        asperaUploadListener(),
        debounceListener(),
        authRequestsListener(),
        authRequest(),
        navigate(),
        navigatelistener(),
    ]);
}

