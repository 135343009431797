
export const configuration = {
    BACKEND_SERVER_URI: process.env.REACT_APP_BACKEND_SERVER_URI,
    ASPERA_SERVER_URI : process.env.REACT_APP_ASPERA_SERVER_URI,
    ASPERA: {
        HOST: process.env.REACT_APP_ASPERA_HOST,
        PORT: 9092,
        FALLBACK_PORT: 9092,
        FALLBACK: false,
        SSH_PORT: 33001,
        CREATE_DIR: true,
        DESTINATION_ROOT: '/',
        AUTHENTICATION: 'token',
        LOCK_TARGET_RATE: true,
        LOCK_RATE_POLICY: true,
        CONTENT_PROTECTION_METHOD: 'encrypt',
    }
};

export const BACKEND_ENDPOINTS = {
    LOGIN: `${configuration.BACKEND_SERVER_URI}/api/token-auth/login/`,
    LOGOUT: `${configuration.BACKEND_SERVER_URI}/api/token-auth/logout/`,

    NAMESPACE: (name) => `${configuration.BACKEND_SERVER_URI}/api/namespaces/${name}/`,
    NAMESPACES: `${configuration.BACKEND_SERVER_URI}/api/namespaces/`,

    SHARE: (namespaceId, name) => `${configuration.BACKEND_SERVER_URI}/api/namespaces/${namespaceId}/shares/${name}/`,
    SHARES: (namespaceId) => `${configuration.BACKEND_SERVER_URI}/api/namespaces/${namespaceId}/shares/`,

    ADMIN_LINK: (namespaceId, shareName, pk) => `${configuration.BACKEND_SERVER_URI}/api/namespaces/${namespaceId}/shares/${shareName}/links/${pk}/`,
    ADMIN_LINKS: (namespaceId, shareName) => `${configuration.BACKEND_SERVER_URI}/api/namespaces/${namespaceId}/shares/${shareName}/links/`,

    USER_LINK: (hashid) => `${configuration.BACKEND_SERVER_URI}/api/userlinks/${hashid}/`,
    
    SEARCH_USER_LINK: (hashid) => `${configuration.BACKEND_SERVER_URI}/api/admin/search/link/${hashid}/`,

    CONTACT_OWNERS: (hashid) => `${configuration.BACKEND_SERVER_URI}/api/userlinks/${hashid}/contact_owners/`,

    LINKEVENT: (hashid) => `${configuration.BACKEND_SERVER_URI}/api/requestevents/?url=${hashid}`,

    ADMINLINKSEARCH: (hashid) => `${configuration.BACKEND_SERVER_URI}/api/admin/link/${hashid}/`,
    ADMIN_SHARE_EMAIL: `${configuration.BACKEND_SERVER_URI}/api/admin/share/email/`,
};

export const ASPERA_ENDPOINTS = {
    FILES_BROWSE: (fileId) => `https://${configuration.ASPERA_SERVER_URI}/files/${fileId}/files`,
    FILES_DELETE: (fileId) => `https://${configuration.ASPERA_SERVER_URI}/files/${fileId}`,
};

export const ASPERA_FILES_SORTS = {
    TYPE: 'type',
    SIZE_A: 'size_a',
    SIZE_D: 'size_d',
    MTIME_A: 'mtime_a',
    MIME_D: 'mtime_d',
};

// Saga Types
export const SAGAS = {
    SUCCESS: (type) => `${type}-success`,
    FAILURE: (type) => `${type}-failure`,
    REQUEST: (type) => `${type}-request`,
};

export const Events = {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete',
    LIST: 'list',
    SEARCH: 'search',
    ASPERA: {
        UPLOAD: 'send',
        DOWNLOAD: 'receive',
    }
};

export const Actions = {
    LOCAL: {
        LOGIN: 'local-login',
        LOGOUT: 'local-logout',
    },
    ADMIN: {
        SET_NAMESPACE: 'set-namespace',
        SET_SHARE: 'set-share',
    },
    BACKEND: {
        LOGIN: 'backend-login',
        LOGOUT: 'backend-logout',
        MODEL: (event) => `backend-model::${event}`,
        CREDS: 'backend-creds',
        CONTACT_OWNERS: 'contact-owners',
    },
    ASPERA: {
        BROWSER_INIT: 'aspera-browser-init',
        BROWSE: 'aspera-browse',
        SET_CREDENTIALS: 'aspera-set-credentials',
        SET_OBJECTS: 'aspera-set-objects',
        UPLOAD: 'aspera-upload',
        DOWNLOAD: 'aspera-download',
        MKDIR: 'aspera-mkdir',
        DELETE: 'aspera-delete',
    },
    BROWSER: {
        NAVIGATE: 'navigate',
        REFRESH: 'refresh',
    },
    DEBOUNCE: 'debounce',
    NOTIFICATIONS: {
        ENQUEUE_SNACKBAR: 'enqueue-snackbar',
        REMOVE_SNACKBAR: 'remove-snackbar',
        CLOSE_SNACKBAR: 'close-snackbar',
    }
};

export const DATE_FORMAT = 'yyyy-MM-dd';

export const BROWSER_ROOT = 'root';

export const ADMIN_LINK_PERMISSIONS = {
    EDIT: 'E',
    VIEW: 'V',
};

export const LOCAL_STORAGE = {
    LOGIN: 'auth-token'
};
