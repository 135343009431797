import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import React from 'react';
import { wrapper } from 'utils';


const mapStateToProps = (state) => ({
    token: state.login.token,
    browserIsInitializing: state.browser.initializing,
    browserIsInitialized: state.browser.initialized,
    browserInitError: state.browser.error,
});


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © UNI.LU'}
      <Link color="inherit" href="https://uni.lu">
        LFT
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}


function Version() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Version: {process.env.REACT_APP_VERSION}
    </Typography>
  );
}

class Layout extends React.Component {


  renderInitMessage(){
    const {browserIsInitializing, browserIsInitialized, browserInitError} = this.props;
    if(browserIsInitializing){
      return (<Alert severity="info">Establishing connection to aspera HTS server.</Alert>);
    }
    if(browserIsInitialized){
      return null;
    } 
    if(!browserIsInitialized && browserInitError){
      return (<Alert severity="error">{browserInitError}</Alert>);
    }
  }


  render(){
      const {children, location} = this.props;

     const InitMessage = this.renderInitMessage()

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <Box sx={{flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header location={location} />
            {InitMessage}
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
              {children}
            </Box>
            {/* <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
              <Copyright />
              <Version />
            </Box> */}
            <Footer />
          </Box>
        </Box>
    );
  }

}



export default wrapper(Layout, {
    conn: [mapStateToProps, null],
    messages: true,
});



