

export function admin_set_namespace_reducer(state, action) {
    return Object.assign({}, state, {
        selectedNamespaceID: action.selectedNamespaceID,
    });
}
export function admin_set_share_reducer(state, action) {
    return Object.assign({}, state, {
        selectedShareName: action.selectedShareName,
    });
}
