import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import React from "react";
import { wrapper } from "utils";

const mapStateToProps = (state) => ({
    links: state.objects.links,
});


class LinkExpiryNotice extends React.Component {
    
    render() {
        const {link} = this.props;
        return (
            <Grid container alignItems="stretch">
                <Alert severity="error">Your access expired on: {link.expiration_date_str}</Alert>
                <Alert severity="info">
                    For extension of access, contact the lcsb-datastewards@uni.lu. Please, include your access link url and origin (link provider) in your request.
                </Alert>
            </Grid>
        )
    };
}

export default wrapper(LinkExpiryNotice, {
    conn: [mapStateToProps, null],
    isAdmin: false,
});
