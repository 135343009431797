import { appReducer } from 'reducers';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

// storage persistance config
// https://github.com/rt2zz/redux-persist
const persistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['login']
};

export const pReducer = persistReducer(persistConfig, appReducer);



// export default () => {
//     let store = createStore(persistedReducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
// }
