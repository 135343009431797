import { Schemas } from 'model/schema';
import { Actions, ASPERA_ENDPOINTS } from "../configuration";

export const initialize = (namespace) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.BROWSER_INIT,
            namespace: namespace,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(namespace.root_file_id),
            schema: [Schemas.FILE],
            errorMessage: "Cannot initialize connection with aspera HTS.",
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
            },
            resolve,
            reject,
        });
    });
};

export const setCredentials = ({kind, id, accessKey, bearerToken, password}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.SET_CREDENTIALS,
            kind: kind,
            id: id,
            accessKey: accessKey,
            bearerToken: bearerToken,
            password: password,
        });
    });
};

export const setAsperaObjects = ({connect, installer}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.SET_OBJECTS,
            connect: connect,
            installer: installer,
        });
    });
};



export const browse = ({fileId}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.BROWSE,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(fileId),
            fileId: fileId,
            schema: [Schemas.FILE],
            errorMessage: "Cannot browse to the specified location.",
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            },
            resolve, reject
        })
    });
};

export const mkdir = ({folderName, fileId}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.MKDIR,
            url: ASPERA_ENDPOINTS.FILES_BROWSE(fileId),
            fileId: fileId,
            schema: Schemas.FILE,
            requestData: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name: folderName.trim(),
                    type: 'folder',
                })
            },
            resolve, reject
        })
    });
};

export const remove = ({fileId}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.DELETE,
            url: ASPERA_ENDPOINTS.FILES_DELETE(fileId),
            fileId: fileId,
            requestData: {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                },
            },
            resolve, reject
        })
    });
};




export const upload = ({path, files}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.UPLOAD,
            path: path,
            files: files,
            schema: [Schemas.FILE],
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            },
            resolve, reject
        })
    });
};


export const download = ({path, files}) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ASPERA.DOWNLOAD,
            files: files,
            path: path,
            schema: [Schemas.FILE],
            requestData: {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            },
            resolve, reject
        })
    });
};





export const ASPERA_DISPATCH = {
    BROWSE_FILES: (fileId, resolve, reject) => (dispatch, getState) => {
        new Promise(function(resolve, reject) {
            dispatch({
                type: Actions.ASPERA.BROWSE,
                url: ASPERA_ENDPOINTS.FILES_BROWSE(fileId),
                fileId: fileId,
                schema: [Schemas.FILE],
                requestData: {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    }
                },
                resolve, reject
            })
        });
    }
};
