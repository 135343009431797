
export function enqueue_snackbar(state, action) {
    return [
                ...state,
                {
                    key: action.key,
                    ...action.notification,
                },
            ]
}

export function close_snackbar(state, action) {
    return [
        state.map(notification => (
            (action.dismissAll || notification.key === action.key)
                ? { ...notification, dismissed: true }
                : { ...notification }
        ))
    ]
}
export function remove_snackbar(state, action) {
    return [
        state.filter(
            notification => notification.key !== action.key,
        ),
        ]
}


