import { Actions, Events, SAGAS } from 'configuration';
import { BackendModel } from "model/appObject";
import { combineReducers } from 'redux';
import { admin_set_namespace_reducer, admin_set_share_reducer } from "./admin";
import { aspera_set_objects, browsing_reducer } from './aspera';
import { aspera_set_credentials, backend_login_reducer, backend_logout_reducer } from './auth';
import { browser_delete, browser_init_failure_reducer, browser_init_reducer, browser_init_success_reducer, browser_mkdir, browser_navigate } from './browser';
import { close_snackbar, enqueue_snackbar, remove_snackbar } from "./notifier";

const initialState = {
    objects: {
        namespaces: {},
        shares: {},
        links: {},
        requestevents: {},
    },
    aspera: {
        files: {},
        installer: {
            set: false,
        },
    },
    browser: {
        initialized: false,
        initializing: false,
        error: null,
        browserItems: {},
        hierarchy: {},
        files: [],
        folderChain: [],
    },
    admin: {
        selectedNamespaceID: null,
        selectedShareName: null,
    },
    login: {
        token: null,
        aspera: {
            kind: null,
            id: null,
            accessKey: null,
            bearerToken: null,
            connect: null,
            installer: null,
        }
    },
    notifications: [],

};

export const objects_handlers = {
    [SAGAS.SUCCESS(Actions.BACKEND.MODEL(Events.LIST))]: BackendModel.reduce.list,
    [SAGAS.SUCCESS(Actions.BACKEND.MODEL(Events.READ))]: BackendModel.reduce.read,
    [SAGAS.SUCCESS(Actions.BACKEND.MODEL(Events.CREATE))]: BackendModel.reduce.create,
    [SAGAS.SUCCESS(Actions.BACKEND.MODEL(Events.DELETE))]: BackendModel.reduce.delete,
    [SAGAS.SUCCESS(Actions.BACKEND.MODEL(Events.UPDATE))]: BackendModel.reduce.update,
};

export const login_handlers = {
    [SAGAS.SUCCESS(Actions.BACKEND.LOGIN)]: backend_login_reducer,
    [SAGAS.SUCCESS(Actions.BACKEND.LOGOUT)]: backend_logout_reducer,
    [Actions.ASPERA.SET_CREDENTIALS]: aspera_set_credentials,
};

export const aspera_handlers = {
    [SAGAS.SUCCESS(Actions.ASPERA.BROWSE)]: browsing_reducer,
    [Actions.ASPERA.SET_OBJECTS]: aspera_set_objects
};

export const browser_handlers = {
    [SAGAS.SUCCESS(Actions.ASPERA.BROWSER_INIT)]: browser_init_success_reducer,
    [SAGAS.FAILURE(Actions.ASPERA.BROWSER_INIT)]: browser_init_failure_reducer,
    [Actions.ASPERA.BROWSER_INIT]: browser_init_reducer,
    [SAGAS.SUCCESS(Actions.BROWSER.NAVIGATE)]: browser_navigate,
    [SAGAS.SUCCESS(Actions.ASPERA.MKDIR)]: browser_mkdir,
    [SAGAS.SUCCESS(Actions.ASPERA.DELETE)]: browser_delete,
};


export const admin_handlers = {
    [Actions.ADMIN.SET_NAMESPACE]: admin_set_namespace_reducer,
    [Actions.ADMIN.SET_SHARE]: admin_set_share_reducer,
};

export const notifications_handlers = {
    [Actions.NOTIFICATIONS.ENQUEUE_SNACKBAR]: enqueue_snackbar,
    [Actions.NOTIFICATIONS.CLOSE_SNACKBAR]: close_snackbar,
    [Actions.NOTIFICATIONS.REMOVE_SNACKBAR]: remove_snackbar,
}

export function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    }
}


const objects = createReducer(initialState.objects, objects_handlers);
const login = createReducer(initialState.login, login_handlers);
const aspera = createReducer(initialState.aspera, aspera_handlers);
const browser = createReducer(initialState.browser, browser_handlers);
const admin = createReducer(initialState.admin, admin_handlers);
const notifications = createReducer(initialState.notifications, notifications_handlers);

export const appReducer = combineReducers({
    objects,
    login,
    aspera,
    browser,
    admin,
    notifications,
});


