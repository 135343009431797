import DateAdapter from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { DATE_FORMAT } from 'configuration';
import { format } from 'date-fns';
import React from 'react';




export const DateInput = ({formik, name, ...props}) => {
    //let currentError = form.errors[field.name] || false;
    // if (typeof(currentError, Array)) {
    //     currentError = currentError[0];
    // }
    // {
        return (
            <LocalizationProvider dateAdapter={DateAdapter} >
                <DesktopDatePicker
                    clearable
                    disablePast
                    name={name}
                    format={DATE_FORMAT}
                    value={formik.values[name]}
                    error={formik.errors[name] && true}
                    inputFormat={DATE_FORMAT}
                    onChange={(date) => formik.setFieldValue('name', format(date, DATE_FORMAT))}
                    renderInput={(params) => <TextField {...params} />}
                    // helperText={currentError}
                    // error={Boolean(currentError)}
                    // onError={(error) => {
                        //     if (currentError) {
                            //         if (error !== currentError) {
                                //             form.setFieldError(field.name, Boolean(currentError));
                                //         }
                                //     }
                                // }}
                                {...props}
                                />
                                </LocalizationProvider>
        );
};
