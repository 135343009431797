import { Actions } from "configuration";


export const set_namespace = (namespaceID) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ADMIN.SET_NAMESPACE,
            selectedNamespaceID: namespaceID,
            resolve,
            reject,
        });
    });
};


export const set_share = (shareName) => (dispatch, getState) => {
    return new Promise(function(resolve, reject) {
        dispatch({
            type: Actions.ADMIN.SET_SHARE,
            selectedShareName: shareName,
            resolve,
            reject,
        });
    });
};
