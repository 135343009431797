import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { wrapper } from 'utils';

const mapStateToProps = (state) => ({
    isLoggeddIn: state.login.token,
});


const styles = {
    lulogo: {
        width: '60px',
        position: 'relative',
        top: '6px',

    },
    lftlogo: {
        width: '35px',
        position: 'relative',
        left: '10px',
        top: '3px',

    }
};  


function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
          navigate(props.href)
        }}
        {...props}
      />
    );
  }



function getTabValue(pathname, isLoggedIn) {
    if (pathname.startsWith('/browse/')) {
        return pathname.includes('/browse/h/') ? 1 : 0;
    }

    const baseTabCount = 1; // 'Home' tab

    if (isLoggedIn) {
        if (pathname.startsWith('/admin/namespaces')) {
            return baseTabCount;
        }
        if (pathname.startsWith('/logout')) {
            return baseTabCount + 1;
        }
    } else {
        if (pathname.startsWith('/login')) {
            return baseTabCount;
        }
    }

    // Default to Home tab
    return 0;
}

class Header extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    render() {
        const {isLoggeddIn, location, classes} = this.props;
        const pathname = location.pathname;
        
        let tabs = [
            {index: 0, label: 'Home', href: '/'}
        ]

        if(pathname.startsWith('/browse/')){
            let helpLink = pathname.replace('/browse/n/', '/browse/h/');
            let browseLink = pathname.replace('/browse/h/', '/browse/n/');
            tabs = [
                {index: 0, label: 'Browse', href: browseLink},
                {index: 1, label: "Help", href: helpLink},
            ]
        } else {
            const startIndex = tabs.length;

            if(isLoggeddIn){
                tabs = [...tabs, ...[
                    {index: startIndex, label: "Namespaces", href: '/admin/namespaces'},
                    {index: startIndex + 1, label: "Logout", href: '/logout'}
                ]]
            } else {
                tabs = [...tabs, ...[
                    {index: startIndex, label: "Login", href: '/login'},
                ]]
            }
        }
        const tabValue = getTabValue(pathname, isLoggeddIn);

        const TabElements = tabs.map(function(tab, index){
            return <LinkTab label={tab.label} key={tab.index} href={tab.href} />;
        });
        return (
                <AppBar color="primary" position="sticky" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={1} alignItems="center">
                            <Typography variant='h5'>    
                            LFT
                            </Typography>
                            <Grid item>
                            <Tabs value={tabValue} textColor="inherit">
                                {TabElements}
                            </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
        );
    }
}

export default wrapper(Header, {
        conn: [mapStateToProps, null],
        styleOverride: styles,
})
